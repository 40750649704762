import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAdmin } from '../../context/AdminContext';
import { useAxios } from '../../context/AxiosContext';

const AdminNavbar = () => {
  const { adminLogout } = useAdmin();
  const { authRequest } = useAxios();
  const navigate = useNavigate();

  // ログアウト時の関数
  const logoutHandler = async (e: any) => {
    e.preventDefault();
    try {
      await authRequest.post('/auth/logout');
      adminLogout();
      navigate('/admin/login');
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className='navbar bg-base-100 drop-shadow-md'>
      <div className='flex-1'>
        <Link to={'/admin'} className='btn btn-ghost normal-case text-xl'>
          わんこCam
        </Link>
      </div>
      <div className='flex-none'>
        <ul className='menu menu-horizontal px-1'>
          <li>
            <details>
              <summary>マガジン</summary>
              <ul className='p-2 bg-base-100 w-64'>
                <li>
                  <Link to={'/admin/magazines/article'}>
                    おすすめペット記事
                  </Link>
                </li>
                <li>
                  <Link to={'/admin/magazines/video'}>おすすめyoutube動画</Link>
                </li>
                <li>
                  <Link to={'/admin/magazines/store'}>
                    おすすめペット関連ショップ
                  </Link>
                </li>
              </ul>
            </details>
          </li>
          <li>
            <Link to={'/admin/challenges'}>コンテスト</Link>
          </li>

          <li>
            <Link to={'/admin/users'}>ユーザー</Link>
          </li>
          <li>
            <Link to={'/admin/posts'}>ポスト</Link>
          </li>
          <li>
            <Link to={'/admin/faq'}>FAQ</Link>
          </li>
          <li>
            <Link to={'/admin/pickup'}>本日のピックアップ</Link>
          </li>
          <li>
            <button onClick={logoutHandler}>ログアウト</button>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default AdminNavbar;
