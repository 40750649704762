import React, { useEffect, useState } from 'react';

import AdminNavbar from '../../../components/admin/AdminNavbar';

import LoadingScreen from '../../../components/LoadingScreen';

import { useNavigate } from 'react-router-dom';
import { useAxios } from '../../../context/AxiosContext';
import { successToast } from '../../../utils/toast';
import { FaqCategory } from '../../../types';

const AdminFaqCategoryCreatePage = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [name, setName] = useState<string>('');
  const [nameError, setNameError] = useState<string>('');
  const [faqCategories, setFaqCategories] = useState<FaqCategory[]>([]);

  const navigate = useNavigate();

  const { authRequest, publicRequest } = useAxios();

  const handleSubmit = async () => {
    try {
      //validation start
      if (!name) {
        setNameError('カテゴリ名を入力してください');
      } else {
        setNameError('');
      }

      if (!name) {
        return;
      }

      //validation end

      setIsLoading(true);

      // 作成を実行
      await authRequest.post('/faq/category', {
        name,
        isActive: true,
        isPublished: true,
      });
      setIsLoading(false);
      navigate('/admin/faq');
      successToast('FAQカテゴリが作成されました');
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  const getFaqCategories = async () => {
    try {
      const { data } = await publicRequest.get('/faq/category');
      setFaqCategories(data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getFaqCategories();
  }, []);

  return (
    <div>
      <AdminNavbar />
      <div className='mx-auto w-11/12 pt-10 pb-10 md:max-w-screen-lg flex flex-col items-center'>
        <h1 className='font-bold text-2xl self-start'>
          よくある質問 カテゴリ追加
        </h1>

        <div className='flex w-full mt-5 space-x-5  justify-center'>
          {/* 既存リスト */}
          <div className='w-full max-w-sm bg-[#FFF8F5] p-5'>
            <p className='text-lg font-bold mb-3'>現在のカテゴリ</p>
            <div className='pl-5'>
              {faqCategories.map((faqCategory) => (
                <li>{faqCategory.name}</li>
              ))}
            </div>
          </div>

          {/* form */}
          <div className='w-full max-w-md flex flex-col'>
            <div className='form-control w-full max-w-md'>
              <label className='label'>
                <span className='label-text'>カテゴリ名</span>
                <span className='label-text-alt'>required</span>
              </label>
              <input
                type='text'
                placeholder='（例）サービスについて'
                className={`input input-bordered w-full max-w-md ${
                  nameError && 'input-error'
                }`}
                onChange={(e) => setName(e.target.value)}
                value={name}
                required
              />
              {/* error用 */}
              <label className='label'>
                <span className='label-text-alt text-red-500 font-semibold'>
                  {nameError}
                </span>
              </label>
            </div>

            <div className='flex self-end mt-5 space-x-5'>
              <button onClick={() => navigate('/admin/faq')} className='btn'>
                キャンセル
              </button>
              <button onClick={handleSubmit} className='btn btn-primary'>
                投稿
              </button>
            </div>
          </div>

          {/* form end */}
        </div>
      </div>
      {isLoading && <LoadingScreen />}
    </div>
  );
};

export default AdminFaqCategoryCreatePage;
