import colors from '../../utils/colors';

const ResetPasswordFailPage = () => {
  return (
    <div className='bg-[#FFFBFF] h-[100vh]'>
      <div className='mx-auto h-full w-11/12 pt-5 pb-20 md:max-w-screen-2xl flex flex-col items-center'>
        <img src='/assets/site-logo.png' className='w-32 md:w-40 mb-10' />
        <div>
          <h1
            className='justify-between px-10  text-xl md:text-2xl text-black font-bold my-6 text-center'
            style={{ color: colors.error }}
          >
            パスワード再設定ページの有効期限が過ぎました
          </h1>
          <p className='text-black text-left my-3'>
            「パスワード再設定ページ」の有効期限は、パスワード再設定のメールが送信されてから24時間です。
          </p>
          <p className='text-black text-left my-3'>
            お手数ではございますが、再度アプリよりパスワード再設定のお手続きをお願いいたします。
          </p>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordFailPage;
