import React from 'react';

const LoadingScreen = () => {
  return (
    <div>
      <div className='absolute top-0 w-[100vw] bg-gray-500 h-[100vh] opacity-30'></div>
      <span className='loading loading-spinner w-36 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'></span>
    </div>
  );
};

export default LoadingScreen;
