import React, { useEffect, useState } from 'react';
import AdminNavbar from '../../../components/admin/AdminNavbar';
import { useAxios } from '../../../context/AxiosContext';
import { Challenge, Post } from '../../../types';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/en';
import { Link } from 'react-router-dom';
import RemovePostFromPickupModal from '../../../components/admin/pickup/RemovePostFromPickupModal';
import { successToast } from '../../../utils/toast';

dayjs.extend(utc);
dayjs.extend(relativeTime);
dayjs.extend(timezone);

const PickupPostListPage = () => {
  const { authRequest } = useAxios();
  const [posts, setPosts] = useState([]);
  const [selectedPost, setSelectedPost] = useState<Post | null>(null);

  //全てのpickupPostsを取得
  const getPickupPosts = async () => {
    try {
      const res = await authRequest('/posts/pickup');
      setPosts(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getPickupPosts();
  }, []);

  const handleRemovePost = async () => {
    try {
      if (selectedPost) {
        await authRequest.post(`/admin/delete-pickup/${selectedPost.id}`);

        getPickupPosts();
        successToast(
          `ポスト(ID:${selectedPost.id})がpickupリストから削除されました`
        );
        //modalを閉じる
        setSelectedPost(null);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <AdminNavbar />
      <div className='mx-auto w-11/12 pt-10 md:max-w-screen-2xl'>
        <h1 className='font-bold text-2xl'>
          本日のピックアップ（スプラッシュ）設定
        </h1>
        <p className='leading-8 my-5'>現在のローテション</p>

        <div className='overflow-x-auto'>
          <table className='table'>
            {/* head */}
            <thead>
              <tr>
                <th>post ID</th>
                <th>Image</th>
                <th>タイトル</th>
                <th>キャプション</th>
                <th>わんこの名前</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {posts.map((post: Post) => (
                <tr key={post?.id}>
                  <th>{post.id}</th>
                  <td>
                    <img
                      className='w-20 h-20 object-cover'
                      src={
                        post.mediaType == 'IMAGE'
                          ? post.mediaUrl
                          : post.mediumThumbnailUrl
                      }
                    />
                  </td>
                  <td>{post.title}</td>
                  <td>
                    {post.caption.length > 20
                      ? post.caption.slice(0, 20) + '...'
                      : post.caption}
                  </td>
                  <td>{post.Pet.name}</td>
                  <td>
                    <Link to={`/admin/posts/${post.id}`} className='btn mr-3'>
                      詳細
                    </Link>
                    <button
                      onClick={() => {
                        setSelectedPost(post);
                      }}
                      className='btn bg-[#FF5663]'
                    >
                      リストから削除
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {selectedPost && (
        <RemovePostFromPickupModal
          post={selectedPost}
          setSelectedPost={setSelectedPost}
          handleSubmit={handleRemovePost}
        />
      )}
    </>
  );
};

export default PickupPostListPage;
