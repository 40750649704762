const ResetPasswordSuccessPage = () => {
  return (
    <div className='bg-[#FFFBFF] h-[100vh]'>
      <div className='mx-auto h-full w-11/12 pt-5 pb-20 md:max-w-screen-2xl flex flex-col items-center'>
        <img src='/assets/site-logo.png' className='w-32 md:w-40 mb-10' />
        <div>
          <h1 className='text-2xl text-black font-bold my-6 text-center'>
            パスワードを変更しました。
          </h1>
          <p className='text-black text-left my-3'>
            パスワードの再設定が完了しました。
          </p>
          <p className='text-black text-left my-3'>
            わんこCamアプリを開き、新しいパスワードでログインをお試しください。
          </p>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordSuccessPage;
