import React, { useRef, useState } from 'react';
import { PostCardProps } from '../../types';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';

import { PlayIcon } from '@heroicons/react/24/solid';
const PostCard: React.FC<PostCardProps> = ({ post }) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [isMuted, setIsMuted] = useState<boolean>(false);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);

  const toggleMute = () => {
    setIsMuted((prevState) => !prevState);
  };

  const togglePlayPause = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying((prevState) => !prevState);
    }
  };

  return (
    <div className='w-full md:max-w-xl mx-auto flex flex-col space-y-3 pt-3 pb-8 bg-white rounded-xl'>
      {/* petの名前とprofile画像 */}
      <div className='flex items-center space-x-3 px-5 py-1'>
        <div className='avatar'>
          <div className='w-8 md:w-12 rounded-full'>
            <img src={post?.Pet?.profileImageUrl} />
          </div>
        </div>
        <p className='font-[500] text-lg text-[#201B17]'>{post?.Pet?.name}</p>
      </div>
      {/* post 画像/動画 */}
      {post?.mediaType === 'IMAGE' ? (
        <img
          src={post?.mediaUrl}
          className='w-full aspect-square object-cover'
        />
      ) : (
        <div className='relative'>
          <video
            ref={videoRef}
            controls={false}
            muted={isMuted}
            onClick={togglePlayPause}
            src={post?.mediaUrl}
            loop={true}
            className='aspect-square object-cover'
            playsInline
            autoPlay={false}
          />
          {!isPlaying && (
            <button
              className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
              onClick={togglePlayPause}
            >
              <PlayIcon className=' p-3 bg-gray-300 rounded-full text-gray-600 w-12 h-12 md:w-16 md:h-16' />
            </button>
          )}

          <button
            onClick={toggleMute}
            className='absolute bottom-1 right-1 p-1 bg-gray-300 rounded-full text-gray-600 hover:text-gray-500 hover:bg-gray-100 focus:outline-none'
          >
            {isMuted ? <VolumeOffIcon /> : <VolumeUpIcon />}
          </button>
        </div>
      )}

      {/* post titleとcaption */}
      <div className='px-5 py-1'>
        <p className='text-xl font-[400] mb-3 text-[#201B17]'>{post?.title}</p>
        <p className='text-md text-[#51443B]'>{post?.caption}</p>
        <hr className='my-3' />
        <p className='text-[#964168] text-sm mb-2 font-[500]'>
          参加したコンテスト
        </p>
        <p className='font-bold text-[#904D00] mb-2'>{post?.Challenge.title}</p>
        <p className='text-[#51443B]'>{post?.Challenge.description}</p>
      </div>
    </div>
  );
};

export default PostCard;
