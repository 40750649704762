import React, { useEffect, useState } from 'react';
import AdminNavbar from '../../../components/admin/AdminNavbar';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAxios } from '../../../context/AxiosContext';
import LoadingScreen from '../../../components/LoadingScreen';
import { successToast } from '../../../utils/toast';

const AdminChallengeEditPage = () => {
  const [title, setTitle] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);

  const [imageUrl, setImageUrl] = useState<string>('');
  const [isPublished, setIsPublished] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [titleError, setTitleError] = useState<string>('');
  const [imageUrlError, setImageUrlError] = useState<string>('');
  const [descriptionError, setDescriptionError] = useState<string>('');
  const [startDateError, setStartDateError] = useState<string | null>('');
  const [endDateError, setEndDateError] = useState<string | null>('');
  const [file, setFile] = useState<File | null>(null);
  const [fileError, setFileError] = useState<string | null>('');
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [mediaType, setMediaType] = useState<string>('ALL');

  const navigate = useNavigate();

  const { authRequest } = useAxios();

  const location = useLocation();
  const challengeId = location.pathname.split('/')[4];

  const getChallengeById = async () => {
    try {
      const res = await authRequest.get(`/challenges/${challengeId}`);
      setTitle(res.data.title);
      setDescription(res.data.description);

      setImageUrl(res.data.imageUrl);
      setIsPublished(res.data.isPublished);
      setStartDate(res.data.startDate);
      setEndDate(res.data.endDate);
      setMediaType(res.data.mediaType);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getChallengeById();
  }, [challengeId]);

  const handleSubmit = async () => {
    try {
      // validation start
      if (!title) {
        setTitleError('タイトルを入力してください');
      } else {
        setTitleError('');
      }
      if (!imageUrl) {
        setImageUrlError('サムネ画像を選択してください');
      } else {
        setImageUrlError('');
      }

      if (!description) {
        setDescriptionError('説明文を入力してください');
      } else {
        setDescriptionError('');
      }
      if (!startDate) {
        setStartDateError('開始日を入力してください');
      } else {
        setStartDateError('');
      }
      if (!endDate) {
        setEndDateError('終了日を入力してください');
      } else {
        setEndDateError('');
      }

      if (!title || !description || !imageUrl || !startDate || !endDate) {
        return;
      }

      // validation end
      setIsLoading(true);

      // ファイルを更新した場合、s3にアップロードしオブジェクトpathを取得
      let s3ObjectPath = null;
      if (file) {
        const s3Response = await authRequest.post(
          '/s3/challenge-image',
          {
            file,
          },
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        );
        s3ObjectPath = s3Response.data;
      }

      // 更新を実行
      await authRequest.patch(`/challenges/${challengeId}`, {
        title,
        description,
        imageUrl: s3ObjectPath ? s3ObjectPath : imageUrl,
        startDate: new Date(startDate),
        endDate: new Date(endDate),
        isPublished,
        mediaType,
      });

      setIsLoading(false);

      navigate('/admin/challenges');
      successToast('正常にコンテストが保存されました');
    } catch (err) {
      setIsLoading(false);

      console.log(err);
    }
  };

  // ファイルをアップロードした時の処理
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];

    if (selectedFile) {
      if (selectedFile.type.startsWith('image/')) {
        setFile(selectedFile);
        setFileError(null);

        // 画像ファイルの場合、プレビューを表示
        const reader = new FileReader();
        reader.readAsDataURL(selectedFile);
        reader.onload = () => {
          setImagePreview(reader.result as string);
        };
      } else {
        setFile(null);
        setFileError('画像ファイルを選択してください。');
        setImagePreview(null);
      }
    } else {
      setFile(null);
      setImagePreview(null);
      if (!imageUrl) {
        setFileError('ファイルを選択してください。');
      }
    }
  };

  return (
    <div className='pb-40'>
      <AdminNavbar />
      <div className='mx-auto w-11/12 pt-10 md:max-w-screen-2xl flex flex-col'>
        <h1 className='font-bold text-2xl mb-5'>コンテスト編集</h1>

        <div className='mt-5 w-full flex space-x-8'>
          {/* card */}
          <div className='card w-[500px] h-1/3  bg-base-100 shadow-xl'>
            <figure className='h-48'>
              <img
                src={imagePreview ? imagePreview : imageUrl}
                alt='Site Image'
              />
            </figure>
            <div className='card-body'>
              <h2 className='card-title text-sm'>{title}</h2>
              <p className='text-xs'>{description}</p>
            </div>
          </div>
          {/* form */}
          <div className='w-full'>
            <div className='form-control'>
              <label className='label'>
                <span className='label-text'>公開設定</span>
                <span className='label-text-alt'>required</span>
              </label>
              <div className='flex items-center space-x-3 mt-2'>
                <input
                  type='checkbox'
                  className='toggle'
                  checked={isPublished}
                  onChange={() => setIsPublished(!isPublished)}
                />
                <span>{isPublished ? '公開' : '非公開'}</span>
              </div>
            </div>
            <div className='form-control w-full  mt-5'>
              <label className='label'>
                <span className='label-text'>タイトル</span>
                <span className='label-text-alt'>required</span>
              </label>
              <input
                type='text'
                placeholder='コンテストのタイトル'
                className={`input input-bordered w-full ${
                  titleError && 'input-error'
                }`}
                onChange={(e) => setTitle(e.target.value)}
                value={title}
              />
              <label className='label'>
                <span className='label-text-alt text-red-500 font-semibold'>
                  {titleError}
                </span>
              </label>
            </div>

            <div className='form-control w-full'>
              <label className='label'>
                <span className='label-text'>サムネイル画像</span>
                <span className='label-text-alt'>required</span>
              </label>

              <label className='btn max-w-xs mt-3' htmlFor='fileSelect'>
                画像ファイルを変更する
              </label>
              <input
                id='fileSelect'
                type='file'
                className='hidden'
                onChange={handleFileChange}
              />
              {file && (
                <>
                  <p className='my-3'>選択された画像</p>
                  <p>{file?.name}</p>
                </>
              )}

              <label className='label'>
                <span className='label-text-alt text-red-500'>{fileError}</span>
              </label>
            </div>

            <div className='form-control mt-5'>
              <label className='label'>
                <span className='label-text'>説明文</span>
                <span className='label-text-alt'>required</span>
              </label>
              <textarea
                className={`textarea textarea-bordered h-24 ${
                  descriptionError && 'textarea-error'
                }`}
                placeholder='この記事に対する説明文'
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              ></textarea>
              <label className='label'>
                <span className='label-text-alt text-red-500 font-semibold'>
                  {descriptionError}
                </span>
              </label>
            </div>

            <div className='form-control mt-5'>
              <label className='label'>
                <span className='label-text'>期間</span>
                <span className='label-text-alt'>required</span>
              </label>

              <div className='flex space-x-3 items-center'>
                <input
                  type='date'
                  placeholder='記事のurl'
                  className={`input input-bordered w-full max-w-md ${
                    startDateError && 'input-error'
                  }`}
                  onChange={(e) => setStartDate(e.target.value)}
                  value={startDate ? startDate.split('T')[0] : ''}
                  required
                  max={endDate ? endDate.split('T')[0] : ''}
                />
                <p>-</p>
                <input
                  type='date'
                  placeholder='記事のurl'
                  className={`input input-bordered w-full max-w-md ${
                    endDateError && 'input-error'
                  }`}
                  onChange={(e) => setEndDate(e.target.value)}
                  value={endDate ? endDate.split('T')[0] : ''}
                  required
                  min={startDate ? startDate.split('T')[0] : ''}
                />
              </div>

              <label className='label'>
                <span className='label-text-alt text-red-500 font-semibold'>
                  {startDateError}
                </span>
                <span className='label-text-alt text-red-500 font-semibold'>
                  {endDateError}
                </span>
              </label>
            </div>

            <div className='form-control mt-5'>
              <label className='label mb-3'>
                <span className='label-text'>許可するメディア</span>
                <span className='label-text-alt'>required</span>
              </label>

              <div className='form-control mb-3'>
                <label className='items-center flex space-x-5 cursor-pointer'>
                  <input
                    type='radio'
                    name='radio-10'
                    className='radio checked:bg-primary'
                    checked={mediaType == 'ALL'}
                    onClick={() => setMediaType('ALL')}
                  />
                  <span className='label-text'>全て</span>
                </label>
              </div>
              <div className='form-control mb-3'>
                <label className='items-center flex space-x-5 cursor-pointer'>
                  <input
                    type='radio'
                    name='radio-10'
                    className='radio checked:bg-primary'
                    checked={mediaType == 'IMAGE'}
                    onClick={() => setMediaType('IMAGE')}
                  />
                  <span className='label-text'>写真のみ</span>
                </label>
              </div>
              <div className='form-control mb-3'>
                <label className='items-center flex space-x-5 cursor-pointer'>
                  <input
                    type='radio'
                    name='radio-10'
                    className='radio checked:bg-primary'
                    checked={mediaType == 'VIDEO'}
                    onClick={() => setMediaType('VIDEO')}
                  />
                  <span className='label-text'>動画のみ</span>
                </label>
              </div>
            </div>

            <div className='flex self-end mt-10 space-x-5'>
              <button
                onClick={() => navigate('/admin/challenges')}
                className='btn'
              >
                キャンセル
              </button>
              <button onClick={handleSubmit} className='btn btn-primary'>
                保存
              </button>
            </div>
          </div>

          {/* form end */}
        </div>
      </div>
      {isLoading && <LoadingScreen />}
    </div>
  );
};

export default AdminChallengeEditPage;
