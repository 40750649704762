import { XMarkIcon } from '@heroicons/react/24/outline';
import React, { useEffect, useState } from 'react';
import { DeleteFaqModalProps } from '../../../types';
import { useAxios } from '../../../context/AxiosContext';
import { successToast } from '../../../utils/toast';
import { marked } from 'marked';
import DOMPurify from 'dompurify';

const DeleteFaqModal: React.FC<DeleteFaqModalProps> = ({
  faq,
  setSelectedFaq,
  getAllFaq,
}) => {
  const { authRequest } = useAxios();
  const [answerForRender, setAnswerForRender] = useState('');

  useEffect(() => {
    if (faq) {
      modifyHtml(faq.markdownAnswer);
    }
  }, [faq]);

  // 削除実行関数
  const handleDelete = async () => {
    try {
      await authRequest.delete(`/faq/${faq?.id}`);
      // modalを閉じるためにSelectedFaqをnullにする
      setSelectedFaq(null);
      // 一覧を更新する
      getAllFaq();
      successToast(`FAQ（ID:${faq?.id}）は正常に削除されました`);
    } catch (err) {
      console.log(err);
    }
  };

  const modifyHtml = async (value: any) => {
    // markedのrendererオプションを設定
    const renderer = new marked.Renderer();
    marked.setOptions({ breaks: true });

    // h1要素にクラスを追加
    renderer.heading = function (text, level) {
      let result = '';
      switch (level) {
        case 1:
          result = `<h1 class="text-5xl font-bold">${text}</h1>`;
          break;
        case 2:
          result = `<h2 class="text-4xl font-bold">${text}</h2>`;
          break;
        case 3:
          result = `<h3 class="text-3xl font-bold">${text}</h3>`;
          break;
        case 4:
          result = `<h4 class="text-2xl font-bold">${text}</h4>`;
          break;
        case 5:
          result = `<h5 class="text-xl font-bold">${text}</h5>`;
          break;
        case 6:
          result = `<h5 class="text-lg font-bold">${text}</h5>`;
          break;

        default:
          result = `<h${level} class="text-lg font-bold">${text}</h${level}>`;
          break;
      }
      return result;
    };

    // li要素にクラスを追加
    renderer.list = function (body, order, number) {
      if (order) {
        return `<ol class='list-decimal ml-5'>${body}</ol>`;
      } else {
        return `<ul class='list-disc ml-5'>${body}</ul>`;
      }
    };

    // リンクはaタグに変換
    renderer.link = function (href, title, text) {
      return `<a href="${href}" title="${
        title || ''
      }" target="_blank" class='underline text-blue-500'>${text}</a>`;
    };

    // markedのbreaksオプションを有効にする
    const options = {
      breaks: false,
    };

    // markedにrendererを指定
    const markedValueForRender = await marked(value, {
      renderer,
      ...options,
    });

    setAnswerForRender(markedValueForRender);
  };

  return (
    <>
      <div
        onClick={(e) => {
          e.stopPropagation();
          setSelectedFaq(null);
        }}
        className=' fixed top-0 left-0 z-[1001] h-full w-full bg-black bg-opacity-50'
      ></div>
      <div
        className='fixed top-1/2 left-1/2 z-[1001]  flex  w-10/12 -translate-x-1/2 -translate-y-1/2  transform rounded-xl bg-white px-6 py-5 md:!w-[600px]'
        id='cancel-modal-container'
      >
        <div
          className='relative h-full w-full flex-1'
          id='cancel-modal-content'
        >
          <div className='mt-3 flex flex-col items-center'>
            <h2 className='font-bold text-2xl text-[#000]'>
              このFAQを削除しますか？
            </h2>
            {/* <p className='my-5 text-[#000]'>
              削除を実行するとデータベース上から完全に削除され、復元は不可能です。
            </p> */}
            <div className=' w-[500px] flex-col space-y-2'>
              <p className='font-semibold'>Q.質問</p>
              <p className='text-md'>{faq?.question}</p>

              <p className='font-semibold'>Q.回答</p>
              <div
                className='border p-3 rounded-md overflow-scroll h-40'
                id='body'
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(answerForRender),
                  }}
                ></div>
              </div>
            </div>
            {faq?.isPublished && (
              <p className='text-red-500 text-lg my-3'>
                ※このFAQは現在公開中です
              </p>
            )}
            <div className='flex space-x-5 mt-5'>
              <button className='btn' onClick={() => setSelectedFaq(null)}>
                キャンセル
              </button>
              <button
                type='submit'
                className='btn btn-primary'
                onClick={handleDelete}
              >
                削除する
              </button>
            </div>
          </div>
          <button
            onClick={() => setSelectedFaq(null)}
            className='btn-sm btn-circle btn absolute -right-2 -top-2'
          >
            <XMarkIcon className='h-5 w-5' />
          </button>
        </div>
      </div>
    </>
  );
};

export default DeleteFaqModal;
