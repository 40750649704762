import ReactGA from 'react-ga4';

const pluginConfig = {
  current_lang: 'jp',
  autoclear_cookies: true, // default: false
  page_scripts: true, // default: false
  test: 'ciao',
  // mode: 'opt-in'                          // default: 'opt-in'; value: 'opt-in' or 'opt-out'
  // delay: 0,                               // default: 0
  // auto_language: 'browser', // default: null; could also be 'browser' or 'document'
  // autorun: true,                          // default: true
  // force_consent: false,                   // default: false
  // hide_from_bots: false,                  // default: false
  // remove_cookie_tables: false             // default: false
  // cookie_name: 'cc_cookie',               // default: 'cc_cookie'
  // cookie_expiration: 182,                 // default: 182 (days)
  // cookie_necessary_only_expiration: 182   // default: disabled
  // cookie_domain: location.hostname,       // default: current domain
  // cookie_path: '/',                       // default: root
  // cookie_same_site: 'Lax',                // default: 'Lax'
  // use_rfc_cookie: false,                  // default: false
  // revision: 0,                            // default: 0

  onFirstAction: function (user_preferences, cookie) {
    // callback triggered only once
  },

  onAccept: function (cookie) {
    if (process.env.NODE_ENV === 'production') {
      console.log('onAccept:', cookie.categories);
      if (cookie.categories.includes('analytics')) {
        ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);
      }
    }
  },

  onChange: function (cookie, changed_preferences) {
    // ...
    console.log('onChange cookie:', cookie);
    console.log('onChange preferences:', changed_preferences);
  },

  languages: {
    en: {
      consent_modal: {
        // title: 'We use cookies!',
        description:
          // 'Hi, this website uses essential cookies to ensure its proper operation and tracking cookies to understand how you interact with it. The latter will be set only after consent. <button type="button" data-cc="c-settings" class="cc-link">Let me choose</button>',
          'This website uses cookies or similar technologies, to enhance your browsing experience and provide personalized recommendations. By continuing to use our website, you agree to our <a href="/privacy-policy">Privacy Policy</a>.\n<button type="button" data-cc="c-settings" class="cc-link">Let me choose</button>',
        primary_btn: {
          text: 'ACCEPT ALL',
          role: 'accept_all', // 'accept_selected' or 'accept_all'
        },
        secondary_btn: {
          text: 'REJECT',
          role: 'accept_necessary', // 'settings' or 'accept_necessary'
        },
      },
      settings_modal: {
        title: 'Cookie Settings',
        save_settings_btn: 'Save settings',
        accept_all_btn: 'Accept all',
        reject_all_btn: 'Reject all',
        close_btn_label: 'Close',
        cookie_table_headers: [
          { col1: 'Name' },
          { col2: 'Domain' },
          { col3: 'Expiration' },
          { col4: 'Description' },
        ],
        blocks: [
          {
            title: 'Cookie usage 📢',
            description:
              'I use cookies to ensure the basic functionalities of the website and to enhance your online experience. You can choose for each category to opt-in/out whenever you want. For more details relative to cookies and other sensitive data, please read the full <a href="#" class="cc-link">privacy policy</a>.',
          },
          {
            title: 'Strictly necessary cookies',
            description:
              'These cookies are essential for the proper functioning of my website. Without these cookies, the website would not work properly',
            toggle: {
              value: 'necessary',
              enabled: true,
              readonly: true, // cookie categories with readonly=true are all treated as "necessary cookies"
            },
          },
          {
            title: 'Performance and Analytics cookies',
            description:
              'These cookies allow the website to remember the choices you have made in the past',
            toggle: {
              value: 'analytics', // your cookie category
              enabled: false,
              readonly: false,
            },
            cookie_table: [
              // list of all expected cookies
              {
                col1: '^_ga', // match all cookies starting with "_ga"
                col2: 'google.com',
                col3: '2 years',
                col4: 'description ...',
                is_regex: true,
              },
              {
                col1: '_gid',
                col2: 'google.com',
                col3: '1 day',
                col4: 'description ...',
              },
            ],
          },
          {
            title: 'Advertisement and Targeting cookies',
            description:
              'These cookies collect information about how you use the website, which pages you visited and which links you clicked on. All of the data is anonymized and cannot be used to identify you',
            toggle: {
              value: 'targeting',
              enabled: false,
              readonly: false,
            },
          },
          {
            title: 'More information',
            description:
              'For any queries in relation to our policy on cookies and your choices, please <a class="cc-link" href="#yourcontactpage">contact us</a>.',
          },
        ],
      },
    },
    jp: {
      consent_modal: {
        // title: 'We use cookies!',
        description:
          // 'Hi, this website uses essential cookies to ensure its proper operation and tracking cookies to understand how you interact with it. The latter will be set only after consent. <button type="button" data-cc="c-settings" class="cc-link">Let me choose</button>',
          '当サイトは、ブラウジング エクスペリエンスを向上させ、コンテンツを提供し、トラフィックを分析するために Cookie を使用します。 「すべて受け入れる」をクリックすると、<a href="/privacy">わんこCam プライバシーポリシー</a> に同意したことになります。なお、同意の設定をカスタマイズすることも可能です。\n<button type="button" data-cc="c-settings" class="cc-link">カスタマイズ</button>',
        primary_btn: {
          text: 'すべて受け入れる',
          role: 'accept_all', // 'accept_selected' or 'accept_all'
        },
        secondary_btn: {
          text: 'すべて拒否する',
          role: 'accept_necessary', // 'settings' or 'accept_necessary'
        },
      },
      settings_modal: {
        title: 'クッキーの設定',
        save_settings_btn: '設定を保存する',
        accept_all_btn: 'すべて受け入れる',
        reject_all_btn: 'すべて拒否する',
        close_btn_label: '閉じる',
        cookie_table_headers: [
          { col1: 'Name' },
          { col2: 'Domain' },
          { col3: 'Expiration' },
          { col4: 'Description' },
        ],
        blocks: [
          {
            title: 'クッキーの使用に関して 📢',
            description:
              '当サイトではウェブサイトの基本的な機能を確保し、オンライン体験を向上させるためにクッキーを使用しています。各カテゴリーごとに、いつでも同意の設定を選択できます。クッキーおよびその他の機密データに関する詳細については、<a href="/privacy" class="cc-link">プライバシーポリシー</a>をお読みください。',
          },
          {
            title: '必須クッキー',
            description:
              'これらのクッキーは、当ウェブサイトを適切に機能させるために不可欠です。これらのクッキーがなければ、ウェブサイトは正常に機能しません。',
            toggle: {
              value: 'necessary',
              enabled: true,
              readonly: true, // cookie categories with readonly=true are all treated as "necessary cookies"
            },
          },
          {
            title: 'パフォーマンスおよび分析クッキー',
            description:
              'これらのクッキーにより、ウェブサイトはお客様が過去に行った選択を記録することができます。',
            toggle: {
              value: 'analytics', // your cookie category
              enabled: false,
              readonly: false,
            },
            cookie_table: [
              // list of all expected cookies
              {
                col1: '^_ga', // match all cookies starting with "_ga"
                col2: 'google.com',
                col3: '2年',
                col4: 'Google Analyticsは、訪問者、セッション、キャンペーンのデータを計算し、サイトの分析レポートのためにサイトの利用状況を追跡するためにこのクッキーを設定します。クッキーは匿名で情報を保存し、ユニークな訪問者を認識するためにランダムに生成された番号を割り当てます。',
                is_regex: true,
              },
              {
                col1: '_gid',
                col2: 'google.com',
                col3: '1日',
                col4: 'Googleアナリティクスは、訪問者がウェブサイトをどのように利用しているかに関する情報を保存し、ウェブサイトのパフォーマンスに関する分析レポートを作成するためにこのクッキーを設定します。収集されるデータには、訪問者数、訪問元、訪問ページ（匿名）などがあります。',
              },
            ],
          },
          {
            title: '広告およびターゲティングクッキー',
            description:
              'これらのクッキーは、お客様がウェブサイトをどのように利用したか、どのページを訪れたか、どのリンクをクリックしたかなどの情報を収集します。データはすべて匿名化され、お客様を特定するために使用されることはありません。',
            toggle: {
              value: 'targeting',
              enabled: false,
              readonly: false,
            },
          },
          {
            title: '詳細な情報に関して',
            description:
              'クッキーに関する当サイトの方針およびお客様の選択に関するお問い合わせは、<a class="cc-link" target="_blank" href="https://yuyosoft.com/contactus/">こちら</a>にご連絡ください。',
          },
        ],
      },
    },
  },
};

export default pluginConfig;
