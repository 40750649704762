import { XMarkIcon } from '@heroicons/react/24/outline';
import React, { useEffect, useState } from 'react';
import { DeletePostModalProps } from '../../../types';
import { useAxios } from '../../../context/AxiosContext';
import { successToast } from '../../../utils/toast';

const DeletePostModal: React.FC<DeletePostModalProps> = ({
  setShowDeleteModal,
  handleDeletePost,
}) => {
  return (
    <>
      <div
        onClick={(e) => {
          e.stopPropagation();
          setShowDeleteModal(false);
        }}
        className=' fixed top-0 left-0 z-[1001] h-full w-full bg-black bg-opacity-50'
      ></div>
      <div
        className='fixed top-1/2 left-1/2 z-[1001]  flex  w-10/12 -translate-x-1/2 -translate-y-1/2  transform rounded-xl bg-white px-6 py-5 md:!w-[600px]'
        id='cancel-modal-container'
      >
        <div
          className='relative h-full w-full flex-1'
          id='cancel-modal-content'
        >
          <div className='mt-3 flex flex-col items-center'>
            <h2 className='font-bold text-2xl'>このポストを削除しますか？</h2>
            <p className='my-5'>データベース上にはinactive状態で残ります。</p>

            <div className='flex space-x-5 mt-5'>
              <button className='btn' onClick={() => setShowDeleteModal(false)}>
                キャンセル
              </button>
              <button
                type='submit'
                className='btn btn-primary'
                onClick={handleDeletePost}
              >
                削除する
              </button>
            </div>
          </div>
          <button
            onClick={() => setShowDeleteModal(false)}
            className='btn-sm btn-circle btn absolute -right-2 -top-2'
          >
            <XMarkIcon className='h-5 w-5' />
          </button>
        </div>
      </div>
    </>
  );
};

export default DeletePostModal;
