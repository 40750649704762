import React, { useEffect, useState } from 'react';
import AdminNavbar from '../../../components/admin/AdminNavbar';
import { useLocation } from 'react-router-dom';
import { useAxios } from '../../../context/AxiosContext';
import { User } from '../../../types';
import DeleteUserModal from '../../../components/admin/users/DeleteUserModal';
import { successToast } from '../../../utils/toast';

const AdminUserDetailsPage = () => {
  const location = useLocation();
  const userId = location.pathname.split('/')[3];

  // deleteModalの開閉に使うstate
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const [user, setUser] = useState<User | null>(null);

  const { authRequest } = useAxios();

  // urlのuserIdを使用して最新の情報を取得
  const getUserDetailsById = async () => {
    try {
      const res = await authRequest.get(`/users/${userId}`);
      setUser(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getUserDetailsById();
  }, [userId]);

  const handleDeleteUser = async () => {
    try {
      const res = await authRequest.post('/admin/delete-user', {
        userId: Number(userId),
      });
      // deleteされたユーザーデータをstateに更新
      setUser(res.data);
      //modalを閉じる
      setShowDeleteModal(false);
      successToast(`ユーザー(ID:${userId})は正常に削除されました`);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <AdminNavbar />
      <div className='mx-auto w-11/12 pt-10 md:max-w-screen-2xl'>
        <h1 className='font-bold text-2xl mb-5'>ユーザー（飼い主）削除</h1>
        <div className='mb-3'>
          <p className='font-light'>username</p>
          <p className='font-bold'>{user?.username}</p>
        </div>
        <div className='mb-3'>
          <p className='font-light'>email</p>
          <p className='font-bold'>{user?.email}</p>
        </div>
        <div className='mb-3'>
          <p className='font-light'>country</p>
          <p className='font-bold'>{user?.country}</p>
        </div>
        {user?.isActive ? (
          <button
            className='btn btn-error text-white mt-5'
            onClick={() => setShowDeleteModal(true)}
          >
            削除する
          </button>
        ) : (
          <p className='font-bold text-error'>このユーザーは削除されました</p>
        )}
      </div>
      {showDeleteModal && (
        <DeleteUserModal
          setShowDeleteModal={setShowDeleteModal}
          handleDeleteUser={handleDeleteUser}
        />
      )}
    </>
  );
};

export default AdminUserDetailsPage;
