import React, { useEffect, useState } from 'react';
import AdminNavbar from '../../../components/admin/AdminNavbar';
import { useLocation } from 'react-router-dom';
import { useAxios } from '../../../context/AxiosContext';
import { Post } from '../../../types';
import DeleteUserModal from '../../../components/admin/users/DeleteUserModal';
import { successToast } from '../../../utils/toast';
import DeletePostModal from '../../../components/admin/posts/DeletePostModal';

const AdminPostDetailsPage = () => {
  const location = useLocation();
  const postId = location.pathname.split('/')[3];

  // モーダルの開閉に使用する
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const [post, setPost] = useState<Post | null>(null);

  const { authRequest } = useAxios();

  // urlのpostIdで最新の情報を取得
  const getPostDetailsById = async () => {
    try {
      const res = await authRequest.get(`/posts/${postId}`);
      setPost(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getPostDetailsById();
  }, [postId]);

  const handleDeletePost = async () => {
    try {
      const res = await authRequest.post(`/admin/delete-post/${postId}`);
      // 削除されたpostをstateに更新
      setPost(res.data);
      //modalを閉じる
      setShowDeleteModal(false);
      successToast(`ポスト(ID:${postId})は正常に削除されました`);
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      <AdminNavbar />
      <div className='mx-auto w-11/12 pt-10 md:max-w-screen-2xl'>
        <h1 className='font-bold text-2xl mb-5'>ポスト削除</h1>
        {/* petの名前とprofile画像 */}
        <div className='flex items-center space-x-3 mb-5'>
          <div className='avatar'>
            <div className='w-10 rounded-full'>
              <img src={post?.Pet?.profileImageUrl} />
            </div>
          </div>
          <p className='font-semibold text-lg'>{post?.Pet?.name}</p>
        </div>
        {/* post 画像/動画 */}
        {post?.mediaType === 'IMAGE' ? (
          <img className='w-64' src={post?.mediaUrl} />
        ) : (
          <video controls src={post?.mediaUrl} />
        )}

        <div className='mb-3 mt-3'>
          <p className='font-light'>title</p>
          <p className='font-bold'>{post?.title}</p>
        </div>
        <div className='mb-3'>
          <p className='font-light'>caption</p>
          <p className='font-bold'>{post?.caption}</p>
        </div>

        {post?.isActive ? (
          <button
            className='btn btn-error text-white mt-5'
            onClick={() => setShowDeleteModal(true)}
          >
            削除する
          </button>
        ) : (
          <p className='font-bold text-error'>このポストは削除されました</p>
        )}
      </div>
      {showDeleteModal && (
        <DeletePostModal
          setShowDeleteModal={setShowDeleteModal}
          handleDeletePost={handleDeletePost}
        />
      )}
    </>
  );
};

export default AdminPostDetailsPage;
