import React, { useEffect, useState } from 'react';
import AdminNavbar from '../../../components/admin/AdminNavbar';
import { useAxios } from '../../../context/AxiosContext';
import { Faq } from '../../../types';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/en';
import { Link } from 'react-router-dom';
import DeleteFaqModal from '../../../components/admin/faq/DeleteFaqModal';

dayjs.extend(relativeTime);

const AdminFaqListPage = () => {
  const { authRequest } = useAxios();
  const [faq, setFaq] = useState([]);
  const [selectedFaq, setSelectedFaq] = useState<Faq | null>(null);

  //全てのおすすめ記事を取得
  const getAllFaq = async () => {
    try {
      const res = await authRequest('/faq');
      setFaq(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getAllFaq();
  }, []);
  return (
    <>
      <AdminNavbar />
      <div className='mx-auto w-11/12 pt-10 md:max-w-screen-2xl'>
        <h1 className='font-bold text-2xl'>よくある質問一覧</h1>
        <Link to={'/admin/faq/create'} className='btn btn-primary my-5 mr-3'>
          新規作成
        </Link>
        <Link
          to={'/admin/faq/category/create'}
          className='btn btn-secondary my-5'
        >
          カテゴリを追加
        </Link>

        <div className='overflow-x-auto'>
          <table className='table'>
            {/* head */}
            <thead>
              <tr>
                <th>ID</th>
                <th>タイトル</th>
                <th>カテゴリ</th>
                <th>ステータス</th>
                <th>作成日</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {faq.map((item: Faq) => (
                <tr key={item?.id}>
                  <th>{item.id}</th>
                  <td>{item.question.substring(0, 15)}...</td>
                  <td>{item.category.name.substring(0, 15)}...</td>
                  <td>{item.isPublished ? '公開中' : '非公開'}</td>
                  <td>{dayjs(item?.createdAt).format('YYYY-MM-DD')}</td>
                  <td>
                    <Link
                      to={`/admin/faq/edit/${item.id}`}
                      className='btn btn-secondary mr-3'
                    >
                      編集
                    </Link>
                    <button
                      onClick={() => setSelectedFaq(item)}
                      className='btn'
                    >
                      削除
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {selectedFaq && (
        <DeleteFaqModal
          faq={selectedFaq}
          setSelectedFaq={setSelectedFaq}
          getAllFaq={getAllFaq}
        />
      )}
    </>
  );
};

export default AdminFaqListPage;
