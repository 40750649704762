import { SyntheticEvent, useEffect, useState } from 'react';

import { useNavigate, useSearchParams } from 'react-router-dom';

import axios from 'axios';

const ResetPasswordPage = () => {
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordError, setNewPasswordError] = useState('');

  const [isFocused, setIsFocused] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get('token');

  const navigate = useNavigate();

  //新しいpasswordをトークンと共に送信
  const handleSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();
    if (!newPassword) {
      setNewPasswordError('パスワードを入力してください');
    }

    if (!newPassword || newPasswordError) {
      return;
    }

    try {
      await axios.post(
        `${process.env.REACT_APP_API_BASEURL}/auth/reset-password`,
        { password: newPassword, confirmPassword: newPassword },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setNewPassword('');
      navigate('/reset-password-success');
    } catch (err) {
      navigate('/reset-password-fail');
      console.log(err);
    }
  };

  // //指定フォーマットでpasswordをvalidateする
  const validatePassword = (text: string) => {
    const requirements = [
      /[a-z]/, // 小文字のアルファベット
      /[A-Z]/, // 大文字のアルファベット
      /[0-9]/, // 数字
      /[-!$%^&*()_+|~=`{}\[\]:\/;<>?,.@#]/, // 記号
    ];

    const isValid = requirements.every((requirement) => requirement.test(text));

    if (text.length > 64 || text.length < 8 || !isValid) {
      setNewPasswordError(
        '半角英字大文字、小文字、数字、記号を1文字以上使用し、8文字以上64文字以下で設定してください'
      );
    } else {
      setNewPasswordError('');
    }

    setNewPassword(text);
  };
  //forgot passwordで発行されたtokenが有効か確認する
  const linkValidation = async () => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_BASEURL}/auth/reset-token-validation`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log('res', res);
    } catch (err) {
      //失効していた場合はreset-password-failに戻す
      navigate('/reset-password-fail');
      console.log(err);
    }
  };

  useEffect(() => {
    linkValidation();
  }, []);

  return (
    <div className='bg-[#FFFBFF] h-[100vh]'>
      <div className='mx-auto w-11/12 pt-5 pb-20 md:max-w-screen-2xl flex flex-col items-center'>
        <img src='/assets/site-logo.png' className='w-32 md:w-42 mb-10' />
        <h2 className='font-bold text-xl mb-5 text-[#000]'>
          新しいパスワードの設定
        </h2>
        <div className='mx-auto w-full max-w-md flex-col pt-4'>
          <form onSubmit={handleSubmit}>
            <div className='relative mb-6 flex w-full flex-col'>
              <p className='mb-3 text-[#000]'>
                新しいパスワードを入力してください
              </p>

              <div
                className={`bg-[#ECE0DA] rounded-t-md border-b-2  relative ${
                  newPasswordError ? 'border-[#BA1A1A]' : 'border-[#51443B]'
                }`}
              >
                <label
                  htmlFor='new_password'
                  className={`text-xs  px-2 ${
                    isFocused || newPassword ? 'opacity-100' : 'opacity-0'
                  } ${newPasswordError ? 'text-[#BA1A1A]' : ' text-[#51443B]'}`}
                >
                  新しいパスワード
                </label>

                <input
                  type='password'
                  className='w-full px-2 pb-1 text-[#51443B] placeholder-[#51443B]  outline-none text-base font-light rounded-md bg-[#ECE0DA]'
                  id='new_password'
                  maxLength={64}
                  onChange={(e) => {
                    validatePassword(e.target.value);
                    setNewPassword(e.target.value);
                  }}
                  value={newPassword}
                  onFocus={() => setIsFocused(true)}
                  onBlur={() => setIsFocused(false)}
                />
                {!isFocused && !newPassword && (
                  <span className='text-[#51443B] absolute top-1/2 left-3 transform -translate-y-1/2 pointer-events-none'>
                    新しいパスワード
                  </span>
                )}
              </div>
              <p className='text-[#BA1A1A] text-sm mt-1'>{newPasswordError}</p>
            </div>

            <button
              type='submit'
              className={`px-5 w-full py-3 text-white  rounded-full font-bold ${
                !newPassword || newPasswordError
                  ? 'bg-gray-300'
                  : 'bg-[#904D00]'
              }`}
            >
              設定する
            </button>
            <span className='invalidFeedback'></span>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordPage;
