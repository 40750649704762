import axios, { AxiosInstance } from 'axios';

import { createContext, PropsWithChildren, useContext } from 'react';
import { useAuth } from './AuthContext';
import { useUser } from './UserContext';
import { useAdmin } from './AdminContext';
import { errorToast } from '../utils/toast';

interface AxiosContextType {
  authRequest: AxiosInstance;
  publicRequest: AxiosInstance;
}

const AxiosContext = createContext<AxiosContextType | undefined>(undefined);

export const AxiosContextProvider = ({ children }: PropsWithChildren) => {
  const { updateAuthToken } = useAuth();
  const { logout } = useUser();
  const { adminLogout, admin } = useAdmin();
  const url = process.env.REACT_APP_API_BASEURL;

  const publicRequest = axios.create({
    baseURL: url,
  });

  const authRequest = axios.create({
    baseURL: url,
  });

  const refreshRequest = axios.create({
    baseURL: url,
  });

  authRequest.interceptors.request.use(
    async function (config) {
      const accessToken = localStorage.getItem('access_token');

      if (accessToken) {
        config.headers['Authorization'] = 'Bearer ' + accessToken;
      }
      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  // accessTokenが失効していた場合のrefreshTokenの処理
  authRequest.interceptors.response.use(
    (response) => {
      return response;
    },

    async (error) => {
      const prevRequest = error?.config;
      //accessToken is expired

      if (error?.response?.status === 401 && !prevRequest._retry) {
        prevRequest._retry = true;
        console.log('accessToken expired', error);
        // refresh token
        try {
          const refreshToken = localStorage.getItem('refresh_token');

          //adminの場合はリフレッシュテーブルが異なる
          let url = '/auth/refresh';
          if (admin) {
            url = '/admin/refresh';
          }

          const res = await refreshRequest.post(
            url,
            {},
            {
              headers: { Authorization: `Bearer ${refreshToken}` },
            }
          );

          // トークンのリフレッシュに成功した場合、accessTokenとrefreshTokenを更新する
          updateAuthToken(res.data.access_token, res.data.refresh_token);

          prevRequest.headers[
            'Authorization'
          ] = `Bearer ${res.data.access_token}`;
          // retry
          return authRequest(prevRequest);
        } catch (err) {
          //refresh token is also expired
          logout();
          adminLogout();
          errorToast('ログインセッションが切れました');
          return Promise.reject(error);
        }
      }
      return Promise.reject(error);
    }
  );

  return (
    <AxiosContext.Provider value={{ authRequest, publicRequest }}>
      {children}
    </AxiosContext.Provider>
  );
};

export const useAxios = (): AxiosContextType => {
  const context = useContext(AxiosContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
