import { useEffect, useState } from 'react';
import LoadingScreen from '../../components/LoadingScreen';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { CalendarDaysIcon } from '@heroicons/react/24/outline';
import AdminNavbar from '../../components/admin/AdminNavbar';
import { useAxios } from '../../context/AxiosContext';
import { useQuery } from '@tanstack/react-query';

export const AdminDashboardPage = () => {
  const { authRequest } = useAxios();
  const [selectedOption, setSelectedOption] = useState('TODAY');
  const [startDate, setStartDate] = useState<Date | undefined>(new Date());
  const [endDate, setEndDate] = useState<Date | undefined>(new Date());

  const setTimeToStartOfDay = (date: Date): Date => {
    date.setHours(0, 0, 0, 0);
    return date;
  };

  const setTimeToEndOfDay = (date: Date): Date => {
    date.setHours(23, 59, 59, 999);
    return date;
  };

  const getNewUserCount = async (startDate: Date, endDate: Date) => {
    try {
      const res = await authRequest(
        `/admin/new-user-count?startDate=${startDate}&endDate=${endDate}`
      );
      return res.data;
    } catch (err) {
      console.log(err);
    }
  };

  const getNewPetCount = async (startDate: Date, endDate: Date) => {
    try {
      const res = await authRequest(
        `/admin/new-pet-count?startDate=${startDate}&endDate=${endDate}`
      );
      return res.data;
    } catch (err) {
      console.log(err);
    }
  };

  const getDeletedUserCount = async (startDate: Date, endDate: Date) => {
    try {
      const res = await authRequest.get(
        `/admin/deleted-user-count?startDate=${startDate}&endDate=${endDate}`
      );
      return res.data;
    } catch (error) {
      console.error(`Failed on getDeletedUserCount request:`, error);
      throw error;
    }
  };

  const getDeletedPetCount = async (startDate: Date, endDate: Date) => {
    try {
      const res = await authRequest.get(
        `/admin/deleted-pet-count?startDate=${startDate}&endDate=${endDate}`
      );
      return res.data;
    } catch (error) {
      console.error(`Failed on getDeletedPetCount request:`, error);
      throw error;
    }
  };

  const { data: newUserCount, isLoading: isNewUserCountLoading } = useQuery({
    queryFn: () =>
      getNewUserCount(
        setTimeToStartOfDay(startDate!),
        setTimeToEndOfDay(endDate!)
      ),
    queryKey: ['new_user_count', startDate, endDate],
    enabled: !!startDate && !!endDate,
  });

  const { data: newPetCount, isLoading: isNewPetCountLoading } = useQuery({
    queryFn: () =>
      getNewPetCount(
        setTimeToStartOfDay(startDate!),
        setTimeToEndOfDay(endDate!)
      ),
    queryKey: ['new_pet_count', startDate, endDate],
    enabled: !!startDate && !!endDate,
  });

  const { data: deletedUserCount, isLoading: isDeletedUserCountLoading } =
    useQuery({
      queryFn: () =>
        getDeletedUserCount(
          setTimeToStartOfDay(startDate!),
          setTimeToEndOfDay(endDate!)
        ),
      queryKey: ['deleted_user_count', startDate, endDate],
      enabled: !!startDate && !!endDate,
    });

  const { data: deletedPetCount, isLoading: isDeletedPetCountLoading } =
    useQuery({
      queryFn: () =>
        getDeletedPetCount(
          setTimeToStartOfDay(startDate!),
          setTimeToEndOfDay(endDate!)
        ),
      queryKey: ['deleted_pet_count', startDate, endDate],
      enabled: !!startDate && !!endDate,
    });

  const options = [
    {
      label: 'Today',
      value: 'TODAY',
    },
    {
      label: 'Last 7 days',
      value: '7DAYS',
    },
    {
      label: 'Last 30 days',
      value: '30DAYS',
    },
    {
      label: 'Last 90 days',
      value: '90DAYS',
    },
    {
      label: 'Last 12 months',
      value: '12MONTHS',
    },
    {
      label: 'Custom',
      value: 'CUSTOM',
    },
  ];

  // optionが変わるたびにstartDate, endDateをセットする
  useEffect(() => {
    const tmpDate = new Date();
    switch (selectedOption) {
      case 'TODAY':
        setStartDate(new Date());
        setEndDate(new Date());
        break;
      case '7DAYS':
        tmpDate.setDate(tmpDate.getDate() - 7);
        setStartDate(tmpDate);
        setEndDate(new Date());
        break;
      case '30DAYS':
        tmpDate.setDate(tmpDate.getDate() - 30);
        setStartDate(tmpDate);
        setEndDate(new Date());
        break;
      case '90DAYS':
        tmpDate.setDate(tmpDate.getDate() - 90);
        setStartDate(tmpDate);
        setEndDate(new Date());
        break;
      case '12MONTHS':
        tmpDate.setMonth(tmpDate.getMonth() - 12);
        setStartDate(tmpDate);
        setEndDate(new Date());
        break;
      case 'CUSTOM':
        break;
    }
  }, [selectedOption]);

  return (
    <>
      <AdminNavbar />
      <main className='container m-auto flex-auto'>
        <div className='mb-10 h-full w-full py-10'>
          <h1 className='text-4xl font-extrabold'>Dashboard</h1>
          <div className='my-10 flex w-full max-w-screen-xl flex-col gap-2 bg-base-200 p-6'>
            <p className='text-sm font-medium text-[rgba(31,42,55,0.60)]'>
              Date Range
            </p>
            <div className='flex items-center'>
              <select
                className='select select-bordered mr-10 w-full max-w-xs'
                onChange={(event) => {
                  setSelectedOption(event.target.value);
                }}
                value={selectedOption}
              >
                {options?.map((option: any, index: number) => {
                  return (
                    <option key={index} value={option.value}>
                      {option.label}
                    </option>
                  );
                })}
              </select>
              <div className='relative'>
                <DatePicker
                  selected={startDate}
                  maxDate={new Date()}
                  onChange={(date) => {
                    setStartDate(date ?? undefined);
                    setSelectedOption('CUSTOM');
                  }}
                  className='border-base-content-20 cursor-pointer rounded-lg border py-3 pl-10 pr-4'
                />
                <CalendarDaysIcon className='absolute left-4 top-1/2 w-5 -translate-y-1/2 transform' />
              </div>
              <div className='mx-1 h-[0.0625rem] w-[0.75rem] bg-base-content' />
              <div className='relative'>
                <DatePicker
                  selected={endDate}
                  maxDate={new Date()}
                  minDate={startDate}
                  onChange={(date) => {
                    setEndDate(date ?? undefined);
                    setSelectedOption('CUSTOM');
                  }}
                  className='border-base-content-2 cursor-pointer rounded-lg border py-3 pl-10 pr-4'
                />
                <CalendarDaysIcon className='absolute left-4 top-1/2 w-5 -translate-y-1/2 transform' />
              </div>
            </div>
          </div>
          <div className='flex gap-6'>
            <div className='w-52 rounded-2xl border border-[rgba(31,42,55,0.10)] px-4 py-6'>
              <p className='text-[rgba(31,42,55,0.60)]'>New users</p>
              <p className='text-3xl font-bold text-[#4A29FF]'>
                {typeof newUserCount === 'number'
                  ? Number(newUserCount).toLocaleString()
                  : '-'}
              </p>
            </div>
            <div className='w-52 rounded-2xl border border-[rgba(31,42,55,0.10)] px-4 py-6'>
              <p className='text-[rgba(31,42,55,0.60)]'>New pets</p>
              <p className='text-3xl font-bold text-[#4A29FF]'>
                {typeof newPetCount === 'number'
                  ? Number(newPetCount).toLocaleString()
                  : '-'}
              </p>
            </div>
          </div>
          <div className='flex gap-6 mt-6'>
            <div className='w-52 rounded-2xl border border-[rgba(31,42,55,0.10)] px-4 py-6'>
              <p className='text-[rgba(31,42,55,0.60)]'>Deleted users</p>
              <p className='text-3xl font-bold text-error'>
                {typeof deletedUserCount === 'number'
                  ? Number(deletedUserCount).toLocaleString()
                  : '-'}
              </p>
            </div>
            <div className='w-52 rounded-2xl border border-[rgba(31,42,55,0.10)] px-4 py-6'>
              <p className='text-[rgba(31,42,55,0.60)]'>Deleted pets</p>
              <p className='text-3xl font-bold text-error'>
                {typeof deletedPetCount === 'number'
                  ? Number(deletedPetCount).toLocaleString()
                  : '-'}
              </p>
            </div>
          </div>
        </div>
      </main>
      {(isNewUserCountLoading ||
        isNewPetCountLoading ||
        isDeletedUserCountLoading ||
        isDeletedPetCountLoading) && <LoadingScreen />}
    </>
  );
};
