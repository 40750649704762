import { XMarkIcon } from '@heroicons/react/24/outline';
import React, { useEffect, useState } from 'react';
import { DeleteArticleModalProps } from '../../../types';
import { useAxios } from '../../../context/AxiosContext';
import { successToast } from '../../../utils/toast';

const DeleteArticleModal: React.FC<DeleteArticleModalProps> = ({
  article,
  setSelectedArticle,
  getAllRecommendedArticles,
}) => {
  const { authRequest } = useAxios();

  // 削除実行関数
  const handleDelete = async () => {
    try {
      await authRequest.delete(`/magazines/article/${article?.id}`);
      // modalを閉じるためにSelectedArticleをnullにする
      setSelectedArticle(null);
      // 一覧を更新する
      getAllRecommendedArticles();
      successToast(`記事（ID:${article?.id}）は正常に削除されました`);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div
        onClick={(e) => {
          e.stopPropagation();
          setSelectedArticle(null);
        }}
        className=' fixed top-0 left-0 z-[1001] h-full w-full bg-black bg-opacity-50'
      ></div>
      <div
        className='fixed top-1/2 left-1/2 z-[1001]  flex  w-10/12 -translate-x-1/2 -translate-y-1/2  transform rounded-xl bg-white px-6 py-5 md:!w-[600px]'
        id='cancel-modal-container'
      >
        <div
          className='relative h-full w-full flex-1'
          id='cancel-modal-content'
        >
          <div className='mt-3 flex flex-col items-center'>
            <h2 className='font-bold text-2xl text-[#000]'>
              このコンテンツを削除しますか？
            </h2>
            <p className='my-5 text-[#000]'>
              削除を実行するとデータベース上から完全に削除され、復元は不可能です。
            </p>
            <div className='card w-[500px] h-1/3  bg-base-100 shadow-xl'>
              <figure className='h-48'>
                <img src={article?.siteImageUrl} alt='Site Image' />
              </figure>
              <div className='card-body max-h-64 overflow-y-scroll'>
                <h2 className='card-title text-sm'>おすすめコンテンツ</h2>
                <h2 className='card-title text-sm'>
                  （画像は{article?.siteTitle}より）
                </h2>
                <p className='text-xs'>{article?.description}</p>
              </div>
            </div>
            {article?.isPublished && (
              <p className='text-red-500 text-lg my-3'>
                ※このコンテンツは現在公開中です
              </p>
            )}
            <div className='flex space-x-5 mt-5'>
              <button className='btn' onClick={() => setSelectedArticle(null)}>
                キャンセル
              </button>
              <button
                type='submit'
                className='btn btn-primary'
                onClick={handleDelete}
              >
                削除する
              </button>
            </div>
          </div>
          <button
            onClick={() => setSelectedArticle(null)}
            className='btn-sm btn-circle btn absolute -right-2 -top-2'
          >
            <XMarkIcon className='h-5 w-5' />
          </button>
        </div>
      </div>
    </>
  );
};

export default DeleteArticleModal;
