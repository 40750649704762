import React, { useEffect, useState } from 'react';
import AdminNavbar from '../../../components/admin/AdminNavbar';
import { useAxios } from '../../../context/AxiosContext';
import { Challenge } from '../../../types';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/en';
import { Link } from 'react-router-dom';
import DeleteChallengeModal from '../../../components/admin/challenge/DeleteChallengeModal';

dayjs.extend(utc);
dayjs.extend(relativeTime);
dayjs.extend(timezone);

const AdminChallengeListPage = () => {
  const { authRequest } = useAxios();
  const [challenges, setChallenges] = useState([]);
  const [selectedChallenge, setSelectedChallenge] = useState<Challenge | null>(
    null
  );

  //全てのchallengeを取得
  const getAllChallenges = async () => {
    try {
      const res = await authRequest('/challenges');
      setChallenges(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getAllChallenges();
  }, []);

  return (
    <>
      <AdminNavbar />
      <div className='mx-auto w-11/12 pt-10 md:max-w-screen-2xl'>
        <h1 className='font-bold text-2xl'>コンテスト一覧</h1>
        <Link to={'/admin/challenges/create'} className='btn btn-primary my-5'>
          新規作成
        </Link>

        <div className='overflow-x-auto'>
          <table className='table'>
            {/* head */}
            <thead>
              <tr>
                <th>ID</th>
                <th>タイトル</th>
                <th>ステータス</th>
                <th>開始日</th>
                <th>終了日</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {challenges.map((challenge: Challenge) => {
                // 開催前、開催中、開催終了、非公開
                let status: string = '非公開';
                if (challenge?.isPublished) {
                  const startDate = new Date(challenge?.startDate);
                  const endDate = new Date(challenge?.endDate);
                  const today = new Date();
                  if (today >= startDate && today <= endDate) {
                    status = '開催中';
                  } else if (today > endDate) {
                    status = '開催終了';
                  } else if (today < startDate) {
                    status = '開催前';
                  }
                }
                return (
                  <tr key={challenge?.id}>
                    <th>{challenge.id}</th>
                    <td>{challenge.title}</td>
                    <td>{status}</td>
                    <td>
                      {dayjs(challenge?.startDate)
                        .utcOffset(0)
                        .format('YYYY-MM-DD')}
                    </td>
                    <td>
                      {dayjs(challenge?.endDate)
                        .utcOffset(0)
                        .format('YYYY-MM-DD')}
                    </td>
                    <td>
                      <Link
                        to={`/admin/challenges/edit/${challenge.id}`}
                        className='btn btn-secondary mr-3'
                      >
                        編集
                      </Link>
                      <button
                        onClick={() => setSelectedChallenge(challenge)}
                        className='btn'
                      >
                        削除
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

      {selectedChallenge && (
        <DeleteChallengeModal
          challenge={selectedChallenge}
          setSelectedChallenge={setSelectedChallenge}
          getAllChallenges={getAllChallenges}
        />
      )}
    </>
  );
};

export default AdminChallengeListPage;
