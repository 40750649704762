import { XMarkIcon } from '@heroicons/react/24/outline';
import React, { useEffect, useState } from 'react';
import { DeleteChallengeModalProps } from '../../../types';
import { useAxios } from '../../../context/AxiosContext';
import { successToast } from '../../../utils/toast';

const DeleteChallengeModal: React.FC<DeleteChallengeModalProps> = ({
  challenge,
  setSelectedChallenge,
  getAllChallenges,
}) => {
  const { authRequest } = useAxios();
  const [hasPosts, setHasPosts] = useState<boolean>(false);

  // 削除実行関数
  const handleDelete = async () => {
    try {
      await authRequest.delete(`/challenges/${challenge?.id}`);
      // modalを閉じるためにSelectedChallengeをnullにする
      setSelectedChallenge(null);
      // 一覧を更新する
      getAllChallenges();
      successToast(`コンテスト（ID:${challenge?.id}）は正常に削除されました`);
    } catch (err) {
      console.log(err);
    }
  };

  // 非公開実行関数
  const handleUnpublish = async () => {
    try {
      await authRequest.patch(`/challenges/${challenge?.id}`, {
        isPublished: false,
      });
      // modalを閉じるためにSelectedChallengeをnullにする
      setSelectedChallenge(null);
      // 一覧を更新する
      getAllChallenges();
      successToast(
        `コンテスト（ID:${challenge?.id}）は非公開状態に変更されました`
      );
    } catch (err) {
      console.log(err);
    }
  };

  //postがあるか確認する
  const findPostsByChallengeId = async () => {
    try {
      const res = await authRequest.get(`/posts/challenge/${challenge?.id}`);
      if (res.data.length > 0) {
        setHasPosts(true);
      } else {
        setHasPosts(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    findPostsByChallengeId();
  }, [challenge]);

  return (
    <>
      <div
        onClick={(e) => {
          e.stopPropagation();
          setSelectedChallenge(null);
        }}
        className=' fixed top-0 left-0 z-[1001] h-full w-full bg-black bg-opacity-50'
      ></div>
      <div
        className='fixed top-1/2 left-1/2 z-[1001]  flex  w-10/12 -translate-x-1/2 -translate-y-1/2  transform rounded-xl bg-white px-6 py-5 md:!w-[600px]'
        id='cancel-modal-container'
      >
        <div
          className='relative h-full w-full flex-1'
          id='cancel-modal-content'
        >
          <div className='mt-3 flex flex-col items-center'>
            <h2 className='font-bold text-2xl'>
              このコンテストを削除しますか？
            </h2>
            <p className='my-5'>
              削除を実行するとデータベース上から完全に削除され、復元は不可能です。
            </p>

            {/* card */}

            <div className='card w-[500px] h-1/3  bg-base-100 shadow-xl'>
              <figure className='h-48'>
                <img src={challenge?.imageUrl} alt='Site Image' />
              </figure>
              <div className='card-body max-h-64 overflow-y-scroll'>
                <h2 className='card-title text-sm'>{challenge?.title}</h2>
                <p className='text-xs'>{challenge?.description}</p>
              </div>
            </div>
            {hasPosts && (
              <p className='text-red-500 text-lg my-3'>
                ※このコンテストには既に投稿があるため削除できません。
                <br />
                削除の代わりに非公開にすることが可能です。
              </p>
            )}

            <div className='flex space-x-5 mt-5'>
              <button
                className='btn'
                onClick={() => setSelectedChallenge(null)}
              >
                キャンセル
              </button>
              {hasPosts ? (
                <button
                  type='submit'
                  className='btn btn-primary'
                  onClick={handleUnpublish}
                >
                  非公開にする
                </button>
              ) : (
                <button
                  type='submit'
                  className='btn btn-primary'
                  onClick={handleDelete}
                >
                  削除する
                </button>
              )}
            </div>
          </div>
          <button
            onClick={() => setSelectedChallenge(null)}
            className='btn-sm btn-circle btn absolute -right-2 -top-2'
          >
            <XMarkIcon className='h-5 w-5' />
          </button>
        </div>
      </div>
    </>
  );
};

export default DeleteChallengeModal;
