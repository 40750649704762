import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAxios } from '../../context/AxiosContext';
import { useAuth } from '../../context/AuthContext';
import { useAdmin } from '../../context/AdminContext';

interface CustomError {
  response: {
    data: {
      message: string;
      statusCode: number;
      // 他に必要なプロパティがあれば追加
    };
  };
}

const AdminLoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');

  const navigate = useNavigate();

  const { publicRequest } = useAxios();
  const { updateAuthToken } = useAuth();
  const { updateAdmin, admin } = useAdmin();

  useEffect(() => {
    if (admin) {
      navigate('/admin');
    }
  }, [admin]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      const res = await publicRequest.post(`/admin/login`, {
        email,
        password,
      });

      updateAuthToken(
        res.data.tokens.access_token,
        res.data.tokens.refresh_token
      );
      updateAdmin(res.data.user);

      // stateは更新できるがlocalstorageへの保存に遅れがあるようなのでlocalstorageへの保存を待つ
      waitForTokenInLocalStorage().then(() => {
        navigate('/admin');
      });
    } catch (err) {
      console.log(err);
      console.log((err as CustomError).response.data.message);
      if ((err as CustomError).response.data.statusCode === 403) {
        setError('Password or email is incorrect');
      } else {
        setError('Something went wrong. Please try again.');
      }
    }
  };

  const waitForTokenInLocalStorage = () => {
    return new Promise((resolve) => {
      const checkLocalStorage = () => {
        const localAccessToken = localStorage.getItem('access_token');

        if (localAccessToken) {
          // トークンがLocalStorageに保存された場合
          resolve(localAccessToken);
        } else {
          // トークンがまだLocalStorageに保存されていない場合、再試行
          setTimeout(checkLocalStorage, 100); // 100ミリ秒ごとにチェック
        }
      };

      // 最初のチェックを実行
      checkLocalStorage();
    });
  };

  return (
    <>
      <div className="mx-auto w-11/12 pt-14 md:max-w-screen-2xl md:pt-20">
        <div className="mx-auto w-3/4">
          <h2 className="font-bold text-4xl mb-5">Admin Login</h2>
          <form onSubmit={handleSubmit} method="post">
            <div className="input-block">
              <label className="font-bold" htmlFor="admin-email">
                Email
              </label>
              <div className="relative my-2">
                <input
                  type="email"
                  className="input input-bordered w-full"
                  id="admin-email"
                  placeholder="Email"
                  name="admin-email"
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <p className="form-error" id="admin-email-error"></p>
            </div>
            <div className="input-block">
              <label className="font-bold" htmlFor="password">
                Password
              </label>
              <div className="relative my-2">
                <input
                  type={`${showPassword ? 'text' : 'password'}`}
                  className="input input-bordered w-full"
                  id="password"
                  placeholder="Password"
                  name="password"
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <div
                  className="absolute"
                  style={{ top: '30%', left: '90%' }}
                  onClick={() => setShowPassword(!showPassword)}
                >
                  <EyeIcon className={`h-5 w-5 ${showPassword && 'hidden'}`} />
                  <EyeSlashIcon
                    className={`h-5 w-5 ${!showPassword && 'hidden'}`}
                  />
                </div>
              </div>
              <p className="text-red-500" id="password-error">
                {error}
              </p>
            </div>
            <button type="submit" className="btn btn-primary my-3">
              ログイン
            </button>
            <span className="invalidFeedback"></span>
          </form>
        </div>
      </div>
    </>
  );
};

export default AdminLoginPage;
