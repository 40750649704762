const NotFoundPage = () => {
  return (
    <div className='bg-[#FFFBFF]'>
      <div className='mx-auto w-11/12 pt-5 h-[100vh] pb-20 md:max-w-screen-2xl flex flex-col items-center justify-between'>
        {/* top */}
        <img src='/assets/site-logo.png' className='w-40 md:w-48  mb-10' />
        {/* center */}
        <div className='flex flex-col items-center'>
          <p className='text-6xl text-[#000]'>404</p>
          <p className='text-md md:text-lg my-5 text-[#000]'>
            お探しのページが見つかりませんでした。
          </p>
          <img src='/assets/404.png' className='w-48 md:w-64 mb-10' />
        </div>
        {/* bottom */}
        <div>
          <a href={process.env.REACT_APP_APP_STORE_URL} target='_blank'>
            <img
              src='/assets/app_store_badge.svg'
              className='w-44 mt-5 min-h-[40px] h-14'
            />
          </a>
          <a href={process.env.REACT_APP_GOOGLE_PLAY_STORE_URL} target='_blank'>
            <img
              src='/assets/google_play_badge.png'
              className='w-44 my-5 min-h-[40px]'
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
