import React, { useEffect, useState } from 'react';
import AdminNavbar from '../../../components/admin/AdminNavbar';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAxios } from '../../../context/AxiosContext';
import LoadingScreen from '../../../components/LoadingScreen';
import YouTube, { YouTubeProps } from 'react-youtube';
import { successToast } from '../../../utils/toast';

const AdminVideoMagazineEditPage = () => {
  const [url, setUrl] = useState<string>('');
  const [youtubeVideoId, setYoutubeVideoId] = useState<string>('');
  const [description, setDescription] = useState<string>('');

  const [isPublished, setIsPublished] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [urlError, setUrlError] = useState<string>('');
  const [descriptionError, setDescriptionError] = useState<string>('');
  const [youtubeVideoIdError, setYoutubeVideoIdError] = useState<string>('');

  const navigate = useNavigate();

  const { authRequest } = useAxios();

  // urlのvideoIdを取得
  const location = useLocation();
  const videoId = location.pathname.split('/')[5];

  // idを使用してマガジンの詳細を取得
  const getRecommendedVideoById = async () => {
    try {
      const res = await authRequest.get(`/magazines/video/${videoId}`);

      setUrl(res.data.url);
      setDescription(res.data.description);
      setYoutubeVideoId(res.data.videoId);
      setIsPublished(res.data.isPublished);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getRecommendedVideoById();
  }, [videoId]);

  const handleSubmit = async () => {
    try {
      //validation start
      if (!url) {
        setUrlError('urlを入力してください');
      } else {
        setUrlError('');
      }

      if (!description) {
        setDescriptionError('説明文を入力してください');
      } else {
        setDescriptionError('');
      }

      if (!youtubeVideoId) {
        setYoutubeVideoIdError('YouTube動画IDを入力してください');
      } else {
        setYoutubeVideoIdError('');
      }
      if (!url || !description || !youtubeVideoId) {
        return;
      }
      //validation end
      setIsLoading(true);

      // 更新の実行
      await authRequest.patch(`/magazines/video/${videoId}`, {
        url,
        videoId: youtubeVideoId,
        description,
        isPublished,
      });

      setIsLoading(false);

      navigate('/admin/magazines/video');
      successToast('正常にコンテンツが保存されました');
    } catch (err) {
      setIsLoading(false);

      console.log(err);
    }
  };

  // youtube playerが準備できた時に発火する関数
  const onPlayerReady: YouTubeProps['onReady'] = (event) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  };

  const opts: YouTubeProps['opts'] = {
    height: '200',
    width: '350',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
    },
  };

  const handleDescriptionChange = (text: string) => {
    // 入力文字数が70文字を超えた場合にエラーを設定
    if (text.length > 70) {
      setDescription(text.slice(0, 70));
      setDescriptionError('70文字以内で入力してください');
    } else {
      // 入力値を更新
      setDescription(text);
      setDescriptionError('');
    }
  };

  return (
    <div>
      <AdminNavbar />
      <div className="mx-auto w-11/12 pt-10 md:max-w-screen-2xl flex flex-col pb-10">
        <h1 className="font-bold text-2xl">おすすめYouTube動画</h1>

        <div className="mt-5 w-full flex space-x-8">
          {/* card */}
          <YouTube
            videoId={youtubeVideoId}
            opts={opts}
            onReady={onPlayerReady}
          />
          {/* form */}
          <div className="w-full">
            <div className="form-control">
              <label className="label">
                <span className="label-text">公開設定</span>
                <span className="label-text-alt">required</span>
              </label>
              <div className="flex items-center space-x-3 mt-2">
                <input
                  type="checkbox"
                  className="toggle"
                  checked={isPublished}
                  onChange={() => setIsPublished(!isPublished)}
                />
                <span>{isPublished ? '公開' : '非公開'}</span>
              </div>
            </div>
            <div className="form-control w-full  mt-5">
              <label className="label">
                <span className="label-text">URL</span>
                <span className="label-text-alt">required</span>
              </label>
              <input
                type="text"
                placeholder="記事のurl"
                className={`input input-bordered w-full ${
                  urlError && 'input-error'
                }`}
                onChange={(e) => setUrl(e.target.value)}
                value={url}
              />
              <label className="label">
                <span className="label-text-alt text-red-500 font-semibold">
                  {urlError}
                </span>
              </label>
            </div>

            <div className="form-control w-full  mt-5">
              <label className="label">
                <span className="label-text">YouTube動画 ID</span>
                <span className="label-text-alt">required</span>
              </label>
              <input
                type="text"
                placeholder="youtube.com/watch?v=<ID> or youtube.com/shorts/<ID>"
                className={`input input-bordered w-full ${
                  youtubeVideoIdError && 'input-error'
                }`}
                onChange={(e) => setYoutubeVideoId(e.target.value)}
                value={youtubeVideoId}
              />
              <label className="label">
                <span className="label-text-alt text-red-500 font-semibold">
                  {youtubeVideoIdError}
                </span>
              </label>
            </div>

            <div className="form-control mt-5">
              <label className="label">
                <span className="label-text">説明文</span>
                <span className="label-text-alt">required</span>
              </label>
              <textarea
                className={`textarea textarea-bordered h-24 ${
                  descriptionError && 'textarea-error'
                }`}
                placeholder="この記事に対する説明文"
                value={description}
                onChange={(e) => handleDescriptionChange(e.target.value)}
              ></textarea>
              <label className="label">
                <span className="label-text-alt text-red-500 font-semibold">
                  {descriptionError}
                </span>
              </label>
            </div>

            <div className="flex self-end mt-5 space-x-5">
              <button
                onClick={() => navigate('/admin/magazines/video')}
                className="btn"
              >
                キャンセル
              </button>
              <button onClick={handleSubmit} className="btn btn-primary">
                保存
              </button>
            </div>
          </div>
          {/* form end */}
        </div>
      </div>
      {isLoading && <LoadingScreen />}
    </div>
  );
};

export default AdminVideoMagazineEditPage;
