import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react';

interface AuthContextType {
  accessToken: string | null;
  refreshToken: string | null;
  updateAuthToken: (
    newAccessToken: string | null,
    newRefreshToken: string | null
  ) => Promise<void>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthContextProvider = ({ children }: PropsWithChildren) => {
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [refreshToken, setRefreshToken] = useState<string | null>(null);

  const updateAuthToken = async (
    newAccessToken: string | null,
    newRefreshToken: string | null
  ) => {
    if (newAccessToken) {
      localStorage.setItem('access_token', newAccessToken);
    } else {
      localStorage.removeItem('access_token');
    }

    if (newRefreshToken) {
      localStorage.setItem('refresh_token', newRefreshToken);
    } else {
      localStorage.removeItem('refresh_token');
    }
    setAccessToken(newAccessToken);
    setRefreshToken(newRefreshToken);
  };

  const getAuthToken = async () => {
    try {
      //state保持のためlocalStorageに保存する
      const localAccessToken = localStorage.getItem('access_token');
      const localRefreshToken = localStorage.getItem('refresh_token');
      setAccessToken(localAccessToken);
      setRefreshToken(localRefreshToken);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getAuthToken();
  }, []);

  return (
    <AuthContext.Provider
      value={{ accessToken, refreshToken, updateAuthToken }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
