const TermsOfUsePage = () => {
  return (
    <div className='bg-[#FFFBFF] h-full'>
      {/* top */}
      <img
        src='/assets/site-logo.png'
        className='w-40 md:w-48  mb-10 mx-auto'
      />
      {/* content */}
      <div className='mx-auto w-11/12 md:max-w-screen-md pb-40'>
        <h2 className='text-xl mb-5'>利用規約</h2>
        <p className='mb-5'>
          Yuyosoft Innovations Inc.
          （以下「当社」といいます。）が提供する"わんこCam
          "と呼ばれるモバイルアプリケーション（以下、「本アプリ」）を利用することにより、ユーザーは本利用規約（以下、「本利用規約」といいます。）に拘束されることに同意するものとします。本アプリの利用、および会員（以下「会員」といいます）への登録、およびサービス（以下「本サービス」といいます。）の利用を希望される方は、本規約をお読みください。本利用規約またはプライバシーポリシーに同意できない場合は、本サービスを利用しないでください。
        </p>
        <p className='font-[500] my-3 text-lg'>第1条　電子契約</p>
        <p className='mb-5'>
          本利用規約は、ユーザーの本サービスの利用に関する法的拘束力のある条件を定める電子契約です。本利用規約は、本アプリに掲載された時点で有効となり、当社によって随時変更されることがあります。本サービスへのアクセス及び利用、又は会員になることにより、ユーザーは本利用規約を承諾し、本利用規約に含まれる又は参照される条件及び通知に同意したものとみなされます。当社が本利用規約の改訂規約を掲載した後、本サービスを継続して利用することにより、ユーザーは改訂規約を明示的かつ拘束力のある形で承諾し、同意したものとみなされます。
        </p>
        <p className='font-[500] my-3 text-lg'>第2条　アクセスと保持</p>
        <p className='mb-5'>
          本利用規約にアクセスし、これを保持するためには、ユーザーは、直接または本アプリのコンテンツにアクセスするデバイスを介してアクセスし、かかるアクセスに関連するサービス料金を支払う必要があります。さらに、ユーザーは、コンピュータおよびモデムまたはその他のアクセス機器を含め、接続に必要なすべての機器を使用する必要があります。
        </p>
        <p className='font-[500] my-3 text-lg'>第3条　本アプリのライセンス</p>
        <p className='mb-3'>
          当社は、ユーザーが本利用規約を遵守することを条件に、本アプリのコピーをダウンロードおよびインストールするための限定的、非独占的、譲渡不能なライセンスをユーザーに付与します。ユーザーは、ユーザーが排他的に管理する1台のデバイス（モバイルであるか否かを問いません）において、本アプリをダウンロードすることができます。当社は、本利用規約においてユーザーに明示的に付与されていない本アプリ
          および本アプリに対するすべての権利を留保します。
        </p>
        <p className='mb-5'>
          ユーザーは、本アプリの新しいバージョンまたは更新されたバージョンには、新しい利用規約または更新された利用規約が含まれる場合があることを了承し、これに同意するものとします。本アプリの新バージョンまたは更新バージョンには、セキュリティの強化または以前のセキュリティ関連の問題の修正が含まれる場合があるため、本アプリの新バージョンをインストールしなかった場合、ユーザーの個人情報の漏洩を含む（これに限定されない）、セキュリティ上のリスクにさらされる可能性があります。
        </p>
        <p className='font-[500] my-3 text-lg'>第4条　参加資格</p>
        <p className='mb-5'>
          本サービスは、過去に本サービスから削除または停止されたことのあるユーザーは利用できません。
        </p>
        <p className='font-[500] my-3 text-lg'>
          第5条　アカウントのセキュリティ
        </p>
        <p className='mb-3'>
          ユーザーは、登録プロセス中に指定したユーザー名とパスワードの機密性を維持する責任を負い、ユーザー名とパスワードの下で発生したすべての活動に対して全責任を負うものとします。ユーザーは、ユーザー名またはパスワードが不正に使用された場合、またはその他のセキュリティ違反があった場合、直ちに当社に通知することとします。
        </p>
        <p className='mb-5'>
          当社は、ユーザーがこの規定に従わなかったことから生じるいかなる損失や損害に対しても責任を負いません。公共または共有の媒体からアカウントにアクセスする際は、パスワードやその他の個人情報を他人が閲覧または記録できないよう、特に注意してください。使用媒体を他人と共有する場合、自動サインイン機能がわんこCamアカウントにリンクされている場合は、その機能を無効にすることをお勧めします。
        </p>
        <p className='font-[500] my-3 text-lg'>第6条　本サービスの利用</p>
        <div className='flex space-x-1 items-start '>
          <p>1.</p>
          <p>
            当社から明示的な許可を得た場合を除き、本アプリまたはわんこCamウェブサイト（以下「本ウェブサイト」といいます。）のコンテンツをコピーまたはキャプチャしたり、それを試みてはいけません。
          </p>
        </div>
        <div className='flex space-x-1 items-start '>
          <p>2.</p>
          <p>
            ユーザーは、本アプリおよび本ウェブサイトのいかなるコンテンツも、コピー、再発行、翻案、利用可能化、またはその他の方法で公衆に伝達、表示、上演、譲渡、共有、配布、またはその他の方法で使用することはできません。
          </p>
        </div>
        <div className='flex space-x-1 items-start '>
          <p>3.</p>
          <p>
            ユーザーは、本サービスの提供の一部を複製する方法でコンテンツを使用してはなりません。
          </p>
        </div>
        <div className='flex space-x-1 items-start '>
          <p>4.</p>
          <p>
            スクレイピングまたは類似の技術を使用して、コンテンツを集約、再利用、再発行、またはその他の方法で利用してはなりません。
          </p>
        </div>
        <div className='flex space-x-1 items-start '>
          <p>5.</p>
          <p>
            ボット、ボットネット、スクリプト、アプリ、プラグイン、拡張機能、またはその他の自動化された手段を使用して、メッセージの送信、コメントの投稿、またはその他の方法でユーザーを装った行動をすることは禁じられています。ユーザーは、本サービスの他のユーザーに対して、そのような技術またはサービスの利用可能性を提供または宣伝してはなりません
          </p>
        </div>
        <div className='flex space-x-1 items-start '>
          <p>6.</p>
          <p>
            ユーザーは、本アプリまたは本ウェブサイトに表示されるコンテンツに含まれる、または表示される商標、著作権、その他の所有権または本利用規約を変更または削除してはならず、変更または削除を試みてはなりません。
          </p>
        </div>
        <div className='flex space-x-1 items-start '>
          <p>7.</p>
          <p>
            攻撃的、虐待的、中傷的、名誉毀損的、わいせつ的、人種差別的、性的、民族的または文化的に攻撃的、下品、暴力、テロリズム、違法行為を助長する、人種、性別、宗教、性的指向を理由に憎悪を扇動する、または当社の独自の合理的な裁量により好ましくないコンテンツは禁止されています。
          </p>
        </div>
        <div className='flex space-x-1 items-start'>
          <p>8.</p>
          <p>
            著作権、商標権、プライバシー権、パブリシティ権、機密情報、その他の権利を含むがこれらに限定されない、第三者の権利を侵害、盗用、不正流用、または侵害する情報、コンテンツ、その他の素材は禁止されています
          </p>
        </div>
        <div className='flex space-x-1 items-start'>
          <p>9.</p>
          <p>
            法律、規則、規制、裁判所命令に違反、違反、または反しているコンテンツ、または当社の単独かつ合理的な見解において違法または不法であるコンテンツは禁止されています。
          </p>
        </div>
        <div className='flex space-x-1 items-start'>
          <p>10.</p>
          <p>
            未承諾または無許可の広告、宣伝メッセージ、スパム、またはその他の形式の勧誘は禁止されています。
          </p>
        </div>
        <div className='flex space-x-1 items-start'>
          <p>11.</p>
          <p>
            ユーザーは、刑事犯罪を構成する行為、民事責任を生じさせる行為、またはその他の法律や規制に違反する行為を犯したり、関与したり、奨励、誘導、勧誘、または促進してはなりません。
          </p>
        </div>
        <div className='flex space-x-1 items-start'>
          <p>12.</p>
          <p>
            ストーカー行為、悪用、脅迫、虐待、その他の嫌がらせをしてはいけません。が、ユーザーが当社従業員または他のユーザーを脅迫、ストーカー、嫌がらせ、暴言をしたと判断した場合、当社は直ちにそのユーザーの本サービス利用を終了し、本サービスへのアクセスを停止する権利を有します。
          </p>
        </div>
        <div className='flex space-x-1 items-start'>
          <p>13.</p>
          <p>
            当社の事前承認なしに、本アプリへのアクセスを販売、譲渡、または販売や譲渡の申し出をしてはなりません。
          </p>
        </div>
        <div className='flex space-x-1 items-start'>
          <p>14.</p>
          <p>
            他のユーザーに関する個人データやその他の情報を収集したり、収集を試みてはなりません。
          </p>
        </div>
        <div className='flex space-x-1 items-start mb-5'>
          <p>15.</p>
          <p>
            当社が採用するデータセキュリティ対策に違反、回避、またはそれらを試みないこと、利用目的外のデータまたは資料にアクセス、またはアクセスを試みないこと、アクセス権限のないサーバーにログイン、またはログインを試みないこと、
            当社のサーバー、システムまたはネットワークの脆弱性をスキャンまたはテストしようとすること、または当社のデータセキュリティまたは認証手続きに違反しようとすること、当社のサーバーまたはシステムをハッキングすること、ウイルスを送信すること、オーバーロードすること、メール攻撃すること、またはクラッシュさせることを含むがこれらに限定されない方法で、本ウェブサイトまたは本サービスを妨害しようとすることは禁止されています。
          </p>
        </div>
        <p className='mb-5'>
          ユーザーは、上記の条件を遵守することに同意し、ユーザーが上記の条件または本利用規約の他の条件のいずれかに違反した場合、当社が独自の裁量で、ユーザーの本アプリの利用を終了させる権利、または適切と思われるその他の措置を講じる権利を有することを認め、同意するものとします。
        </p>
        <p className='font-[500] my-3 text-lg'>第7条　表明および保証</p>
        <p className='mb-3'>
          ユーザーは、当社に対し、以下のとおり表明し、保証するものとします：
        </p>
        <div className='flex space-x-1 items-start '>
          <p>1.</p>
          <p>
            ユーザーのコンテンツおよびその各部分は、ユーザーによるオリジナル作品であること。
          </p>
        </div>
        <div className='flex space-x-1 items-start '>
          <p>2.</p>
          <p>
            ユーザーのコンテンツおよび本プラットフォーム上でのその利用可能性は、知的財産権、プライバシー権、または機密情報に関する権利を含みますが、これらに限定されない、いかなる第三者の権利も侵害せず、また侵害することはありません。
          </p>
        </div>
        <div className='flex space-x-1 items-start mb-5'>
          <p>3.</p>
          <p>
            ユーザーが本アプリに投稿するコメントを含むユーザーのコンテンツは、違法、攻撃的、虐待的、中傷的、わいせつ、人種差別的、性的に露骨、民族的または文化的に攻撃的、下品でなく、暴力、テロ、違法行為を助長せず、人種、性別、宗教、性的指向を理由とする憎悪を扇動しないものとします。
          </p>
        </div>
        <p className='mb-5'>
          当社は、ユーザーのコンテンツが前述の表明または保証に違反する、または他人の権利を侵害する、または法律、規則または規制に違反すると当社が判断した場合、ユーザーのコンテンツを削除する権利、プラットフォームへのアクセスを一時停止または終了する権利、およびあらゆる法的救済を追求する権利を留保します。
        </p>
        <p className='font-[500] my-3 text-lg'>第8条　アップロード</p>
        <p className='mb-5'>
          アップロードのリスクはユーザーが負うものとします。
          当社は、ユーザーがアップロードしたコンテンツや情報（情報、写真、その他の画像、知的財産を含みますが、これらに限定されません）を保護するために、合理的なセキュリティ手段を使用します。しかしながら、当社は、ユーザーのコンテンツの無許可のコピーや配布がないことを保証することはできず、当社が許可していないユーザーのコンテンツのコピーや使用に対して責任を負うこともありません。ユーザーは、そのような無許可のコピーや利用に対して、当社に対して有するいかなる請求権も、責任理論の如何を問わず、ここに放棄し、永久に放棄するものとします。ここで当社によって使用されるユーザーのコンテンツを保護するためのセキュリティ対策は、「現状のまま」提供され、使用されるものです。
        </p>
        <p className='font-[500] my-3 text-lg'>第9条　変更</p>
        <p className='mb-5'>
          当社は、予告の有無にかかわらず、いつでも本サービス（またはその一部）を一時的または恒久的に変更または中止する権利を留保します。
        </p>
        <p className='mb-5'>
          ユーザーは、当社がサービスの変更、一時停止または中止について、ユーザーまたはいかなる第三者に対しても責任を負わないことに同意するものとします。
        </p>
        <p className='font-[500] my-3 text-lg'>第10条　契約期間</p>
        <p className='mb-3'>
          本利用規約は、ユーザーが本サービスを利用している間、完全な効力を有します。
        </p>
        <p className='mb-5'>
          当社は、理由の如何を問わず、ユーザーが本サービスに登録する際に提供した電子メールアドレス、またはユーザーが後に当社に提供した他の電子メールアドレスに通知を送信することにより、ユーザーによる本アプリの利用を終了することができます。本アプリの利用終了に関するすべての決定は、当社の独自の裁量で行われるものとします。当社は、本アプリの利用を終了する前に、ユーザーに通知する必要はありません。当社は、本アプリの利用終了の理由を開示する必要はなく、また開示が禁止されている場合もあります。ユーザーによる本アプリの利用が終了した後も、本利用規約は有効に存続します。その性質上、本利用規約の終了後も存続しうる全ての条項は、当該終了後も存続するものとみなされます
        </p>
        <p className='font-[500] my-3 text-lg'>
          第11条　サードパーティーコンテンツ
        </p>
        <p className='mb-3'>
          当社は、本サービスにおいて、第三者のコンテンツを提供することがあり、また、これらの情報に関心のある方へのサービスとして、第三者のアプリやコンテンツ（以下、総称して「第三者コンテンツ」）へのリンクを提供することがあります。当社は、第三者コンテンツを管理、保証、採用するものではなく、第三者コンテンツに関して、その正確性や完全性を含め、いかなる表明や保証も行いません。
          当社は、本サービス上の第三者コンテンツについて責任を負いません。ユーザーは、自己責任においてかかる第三者コンテンツを利用するものとします。
        </p>
        <p className='mb-5'>
          本サービスには、ユーザーの便宜のためにのみ、他のウェブサイトやサービス（以下、総称して「参照サイト」）へのリンクや参照が含まれている場合があります。当社は、参照サイト、または参照サイトに含まれる、もしくは参照サイトを通じてアクセスできる情報、資料、製品、サービスを保証するものではありません。また、本サービス上または本サービスを通じて見つけた広告主との通信や取引、または広告主のプロモーションへの参加は、ユーザーと関連する広告主との間でのみ行われます。参照サイトへのアクセスおよびその利用は、専らユーザー自身の責任において行われるものとします。
        </p>
        <p className='font-[500] my-3 text-lg'>第12条　知的財産権</p>
        <p className='mb-3'>
          本アプリ、当社のロゴ、本サービスに含まれるその他の製品名やサービス名、スローガンは、当社による事前の許可なく、その全部または一部をコピー、模倣、使用することはできません。
        </p>
        <p className='mb-3'>
          当社は、別段の記載がある場合を除き、本アプリと本サービスにおける全ての所有権を保持します。本アプリには、当社とそのライセンサーの著作権で保護された素材、商標、その他の所有権で保護された情報が含まれています。当社がユーザーに書面による明示的な許可を与えた場合を除き、そのような専有情報をコピー、変更、公開、送信、配布、実行、表示、または販売することはできません。
        </p>
        <p className='mb-3'>
          本利用規約に別段の定めがある場合を除き、全てのコンテンツは当社の著作物であり、わんこCamユーザーのみが使用できます。コンテンツの他者への配布は固く禁じられています。ユーザーが本条項に違反した場合、違反の恐れがある場合、または当社が損害を受けた場合、弊社が有する他の権利または救済措置に加えて、保証金を支払うことなく、その違反を禁止する差止命令を受ける権利を有することに同意するものとします。
        </p>
        <p className='mb-3'>
          本アプリ、本サービスまたは本アプリのその他の名称、商標、製品名、サービス名を利用したメタタグやその他の隠しテキストを、当社の事前の書面による許可なく使用することはできません。さらに、すべてのカスタムグラフィック、ボタンアイコン、スクリプトを含む本サービスの外観と雰囲気を、当社の事前の許可なく、その全部または一部をコピー、模倣、使用することはできません。
        </p>
        <p className='mb-5'>
          本サービスは 当社によって所有および運営されています。
          特に明記されていない限り、本サービス上のすべてのコンテンツおよびその他のマテリアルには、わんこCamのロゴ、ビジュアル
          インターフェイス、グラフィックス、デザイン、コンピレーション、情報、ソフトウェア、コンピューター
          コード (ソース コードまたはオブジェクト
          コードを含む)、サービス、テキスト、 画像、情報、データ、サウンド
          ファイル、その他のファイル、およびその選択と配置
          (総称して「マテリアル」)
          は、著作権法、特許法、商標法、国際規約、およびその他すべての関連する知的財産によって保護されています。
        </p>
        <p className='font-[500] my-3 text-lg'>
          第13条　ユーザーコンテンツの所有権
        </p>
        <p className='mb-5'>
          当社は、ユーザーのコンテンツの所有権を主張しません。
          ただし、ユーザーのコンテンツに関しては、ユーザーは、当社に対し、本サービス上でかかるコンテンツを本アプリ内及び広告等で使用、配布、複製、および公に表示するための世界規模のロイヤリティフリーの非独占的ライセンスを付与するものとします。
        </p>
        <p className='font-[500] my-3 text-lg'>第14条　著作権</p>
        <p className='mb-3'>
          個人または団体の著作権およびその他の知的財産権
          (プライバシーおよびパブリシティの権利を含む)
          を侵害する情報の提出または投稿を禁止します。
        </p>
        <p className='mb-3'>
          著作権に関するお問い合わせ・通報は本アプリ内のフォームよりご連絡ください。
        </p>
        <p className='mb-5'>
          その他のすべてのユーザーの権利を侵害するコンテンツ、またはその他の権利を侵害するコンテンツ、名誉毀損、ポルノ、わいせつ、人種差別的、または広範な犯罪を引き起こす可能性があるとユーザーが考えるコンテンツ、またはなりすまし、嫌がらせ、スパム、またはその他の権利侵害を構成するコンテンツを発見した場合、本アプリ内のフォームからご報告ください。
        </p>
        <p className='font-[500] my-3 text-lg'>
          第15条　コンテンツに対する責任
        </p>
        <p className='mb-3'>
          ユーザーは、他のユーザーによって作成またはアップロードされたコンテンツをレビューすることはできず、またレビューしないこと、および当社もいかなる義務も負わないことをユーザーはここに認め、これに同意するものとします。
          不適切なコンテンツ、第三者の権利を侵害するコンテンツ、またはその可能性のあるコンテンツ、または本利用規約や適用法に違反してアップロードされたコンテンツがないか
          本アプリを監視することを引き受ける、または義務を負うことはありません。
        </p>
        <p className='mb-5'>
          当社は、法律で認められる最大限の範囲で、ユーザーが本アプリにアップロードしたコンテンツから生じる可能性のある以下のような責任を一切排除します。
          知的財産権、プライバシーの権利、またはパブリシティ権の侵害に関する申し立て、中傷的、ポルノ的、わいせつまたは攻撃的なコンテンツに関する申し立て、または完全性、正確性、最新性、または信頼性に関する申し立てが含まれますが、これらに限定されません。ユーザーは、当社に対して、上記のいずれかに関する請求を主張する権利を取り消し不能の形で放棄するものとします。
        </p>
        <p className='font-[500] my-3 text-lg'>第16条　本アプリ内のポイント</p>
        <p className='mb-5'>
          ポイントは、理由を問わず、一切払戻しを行いません。ただし、法令上必要な場合はこの限りではありません。
        </p>
        <p className='font-[500] my-3 text-lg'>
          第17条　(有料サービス等の購入)
        </p>
        <p className='mb-3'>
          会員は、当社所定の条件で、会員のポイント及び「わんこプレミアム」と称する有料サブスクリプションサービス(以下、ポイントと有料サブスクリプションサービスを総称して「有料サービス等」といいます。)の提供を受けることができます。
        </p>
        <div className='flex space-x-1 items-start mb-3'>
          <p>1.</p>
          <p>
            有料サービス等
            <br />
            有料サービス等は、会員が本利用規約に同意し、当社の定める手段及び支払い方法により有料サービス等を購入した時点で利用できるものとします。
          </p>
        </div>
        <div className='flex space-x-1 items-start mb-3'>
          <p>2.</p>
          <p>
            支払条件
            <br />
            会員が有料サービス等を購入することを選択した場合、その支払いはアプリ上で行われることに同意するものとします。
          </p>
        </div>
        <div className='flex space-x-1 items-start mb-3'>
          <p>3.</p>
          <p>
            有料サービス等の利用ができない場合
            <br />
            当社は、有料サービス等を利用しようとする会員が次の各号に該当する場合には、その利用を承諾せず、または承諾を留保できます。
            <br />
            -実名でなかったり他人の名義を利用した場合
            <br />
            -虚偽の情報を記載したり、当社が提示する内容を記載しなかった場合
            <br />
            -同一のIPアドレスでの大量決済である場合、類似ID
            での連続決済である場合、または盗用履歴などを考慮してリスクがあるIPアドレスと当社が判断したIPアドレスでの決済など盗用による購入と判断された場合
            <br />
            -未成年者が法定代理人の同意なしに申請した場合
            <br />
            -未成年者が法令によって利用が禁止される有料サービスを利用しようとする場合
            <br />
            -決済に対する支払が現に行われていないまたは行われていない可能性がある場合
            <br />
            -著作権侵害等、本利用規約に違反した会員である場合
            <br />
            -本利用規約に基づいて利用制限を受けた会員の場合
            <br />
            -サービスに係る設備の余裕がないか、技術上または業務上の問題がある場合
            <br />
            -その他の利用申請を承認することが関係法令に違反し、または社会安寧秩序および公序良俗、当社の関連利用規約等に違反する場合
          </p>
        </div>
        <div className='flex space-x-1 items-start mb-3'>
          <p>4.</p>
          <p>
            未成年者による利用
            <br />
            会員が未成年者（19歳未満）または制限行為能力者である場合には、会員は法定代理人（親権者など）の同意を得るなど、法的行為に必要な手続きを行った上で、本利用規約を利用するものとみなします。
            <br />
            未成年の会員が、購入手続きの際に成人の個人情報を盗むなど、成人であることを示し、または当社に成人であると思わせた場合、有料サービス等の利用を取消すことはできません。
          </p>
        </div>
        <p className='font-[500] my-3 text-lg'>
          第18条　（会員による有料サブスクリプションサービスの終了）
        </p>
        <div className='flex space-x-1 items-start mb-3'>
          <p>1.</p>
          <p>
            解約
            <br />
            会員は、有料サブスクリプションサービスについて、利用を解約することができます。キャンセルしない限り、プランは自動で更新されます。
            <br />
            <br />
            会員が有料サブスクリプションサービスの期間内に解約した場合、差分の金額は返金されません。
          </p>
        </div>
        <div className='flex space-x-1 items-start mb-5'>
          <p>2.</p>
          <p>
            有料サブスクリプションの取消しおよび返金
            <br />
            当社は、一度購入した有料サブスクリプションの取り消しや返金を一切承っておりません。やむを得ない理由で有料サービス等の取り消しや返金が必要な場合は、ご利用の各AppleまたはGoogleにお問い合わせください。
          </p>
        </div>
        <p className='font-[500] my-3 text-lg'>第19条　本利用規約違反の対応</p>
        <p className='mb-3'>
          当社は、ユーザーが本利用規約に違反したと当社の裁量で判断した場合、ユーザーの本サービスへのアクセスを通知なしに終了するというポリシーを採用しています。また、当社は、反復的な侵害の有無にかかわらず、当社の独自の裁量により、本サービスへのアクセスを制限したり、ユーザーのアクセスを終了したりする場合があります。
        </p>
        <p className='mb-5'>
          これらの本利用規約への度重なる違反の結果、アクセスが停止されたユーザーに対して、当社は返金を提供しないことに注意してください。
        </p>
        <p className='font-[500] my-3 text-lg'>第20条　責任の制限</p>
        <p className='mb-3'>
          いかなる場合においても、当社は、直接的、間接的、懲罰的、一般的、特別、例示的、補償的、結果的、または付随的であるかを問わず、本アプリ、またはその使用に起因または関連して生じるいかなる損害に対しても責任を負わないものとします。本アプリに含まれる内容、または当社からの書面または口頭でのコミュニケーションに含まれる内容は、いかなる約束、誓約、保証または保証を行うものとして解釈されるものではなく、声明および免責条項に反して、これらはすべてここで明示的に否認されます。以下で詳細を述べます。
        </p>
        <p className='mb-5'>
          本アプリのコンテンツおよび機能、ならびに本アプリが提供する本サービスは、「現状のまま」提供されます。
          明示的か黙示的かを問わず、商品性、特定の目的への適合性、権原および非侵害の黙示的な保証を含むがこれらに限定されない、いかなる種類の保証もありません。当社は、本アプリのコンテンツの所有権、正確性、完全性、適切性について、または本アプリの機能が中断されないこと、エラーがないこと、またはウイルスや第三者の攻撃がないことについて、明示的か黙示的かを問わず、いかなる保証も行いません。
          ユーザーは、本サービスの使用はユーザー自身のリスクで行われることに同意します。ユーザーは、法律で認められる最大限の範囲で、本利用規約、本アプリに起因または関連して生じる間接的、特別、付随的、派生的、または懲罰的な損害について、偶発的かどうかにかかわらず、当社が一切の責任を負わないことに同意するものとします。
        </p>
        <p className='font-[500] my-3 text-lg'>第21条　 プライバシー</p>
        <p className='mb-5'>
          本アプリの使用には、当社のプライバシーポリシーが適用されます。当社プライバシーポリシーをご参照ください。
        </p>
        <p className='font-[500] my-3 text-lg'>第22条　 譲渡禁止</p>
        <p className='mb-5'>
          会員は、本規約に基づいて発生した契約上の地位および権利義務を他人に譲渡し、担保権の対象とする等、一切の処分行為をすることができないものとします。
        </p>
        <p className='font-[500] my-3 text-lg'>第23条　可分性</p>
        <p className='mb-5'>
          本利用規約のいずれかの条項が、管轄権を有する裁判所によって無効であると判断された場合でも、当該条項の無効性は本利用規約の残りの条項の有効性に影響を与えるものではなく、完全な効力を維持するものとします。
          本利用規約のいずれかの条項の権利放棄は、当該条項またはその他の条項のさらなる権利放棄または継続的な権利放棄とみなされないものとします。
          さらに、当社が本利用規約の条項を執行しなかったとしても、当該条項の放棄とは見なされず、将来のいかなる時点においても当該条項を執行する当社の能力に影響を与えるものではありません。
        </p>
        <p className='font-[500] my-3 text-lg'>第24条　準拠法、裁判管轄</p>
        <p className='mb-3'>
          本利用規約は日本語を正文とし、その成立、効力発生、解釈に当たっては日本法を準拠法とします。
        </p>
        <p className='mb-5'>
          本アプリおよび本サービスに起因または関連して当社とユーザーとの間で生じた紛争については、東京地方裁判所を第一審の専属的合意管轄裁判所とします。
        </p>
        <p className='font-[500] my-3 text-lg'>第25条　その他</p>
        <p className='mb-3'>
          本利用規約に含まれるセクションの見出しは参照のみを目的としており、本利用規約の意味や解釈にはいかなる形でも影響を与えません。
        </p>
        <p className='mb-3'>
          本利用規約に関するご質問は、当社までお問い合わせください。
        </p>
        <p className='mb-5'>
          わんこCamお問い合わせ窓口　
          <span className='underline'>info@wancocam.com</span>
        </p>
        <p className='mb-5'>
          当社は、本利用規約で明示的に付与されていないすべての権利を留保します。
        </p>
        <p>この利用規約は、2024年1月19日に最終更新されました。</p>
      </div>
      {/* footer */}
      <div className='bg-[#F3DFD2] py-10 flex flex-col items-center'>
        <a
          href='https://yuyosoft.com/'
          target='_blank'
          rel='noopener noreferrer'
          className='mb-5 text-[#51443B] text-sm underline cursor-pointer'
        >
          Yuyosoft Innovations Inc.
        </a>
        <p className='text-[#51443B] text-sm'>
        &copy;2024 Yuyosoft Innovations Inc.{' '}
        </p>
      </div>
    </div>
  );
};

export default TermsOfUsePage;
