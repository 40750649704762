import React, { useEffect, useRef, useState } from 'react';
import { RankingItemProps } from '../types';
import colors from '../utils/colors';

export const RankingItemCard: React.FC<RankingItemProps> = ({
  RankingItem,
  petId,
}) => {
  const [isTopThree, setIsTopThree] = useState<boolean>(false);
  const [isUserItem, setIsUserItem] = useState<boolean>(false);

  const RankingTopThree = (): any => {
    let image;
    if (RankingItem !== null) {
      if (RankingItem.rank === 1 && RankingItem.duplicated === false)
        image = (
          <img
            src={'/assets/ranking/1.png'}
            style={{ width: 38, height: 38 }}
          />
        );
      else if (RankingItem.rank === 1 && RankingItem.duplicated)
        image = (
          <img
            src={'/assets/ranking/1_duplicated.png'}
            style={{ width: 38, height: 38 }}
          />
        );
      else if (RankingItem.rank === 2 && RankingItem.duplicated === false)
        image = (
          <img
            src={'/assets/ranking/2.png'}
            style={{ width: 38, height: 38 }}
          />
        );
      else if (RankingItem.rank === 2 && RankingItem.duplicated)
        image = (
          <img
            src={'/assets/ranking/2_duplicated.png'}
            style={{ width: 38, height: 38 }}
          />
        );
      else if (RankingItem.rank === 3 && RankingItem.duplicated === false)
        image = (
          <img
            src={'/assets/ranking/3.png'}
            style={{ width: 38, height: 38 }}
          />
        );
      else if (RankingItem.rank === 3 && RankingItem.duplicated)
        image = (
          <img
            src={'/assets/ranking/3_duplicated.png'}
            style={{ width: 38, height: 38 }}
          />
        );
    }
    return image;
  };

  useEffect(() => {
    if (RankingItem) {
      if (RankingItem.rank < 4) setIsTopThree(true);
      else setIsTopThree(false);
      if (RankingItem.id === Number(petId)) setIsUserItem(true);
      else setIsUserItem(false);
    }
  }, []);

  return (
    <div
      className={`w-full flex flex-row items-center !bg-[#FFF8F5] rounded-2xl my-2 shadow-lg ${
        isUserItem
          ? 'border-4 border-[#F53030] border-dashed'
          : 'border border-[#CAC4D0]'
      }`}
    >
      <div className='rounded-full w-10 h-10 items-center mx-1.5 flex-shrink-0'>
        {RankingItem && RankingItem.rank < 4 ? (
          <RankingTopThree />
        ) : (
          <p>
            {RankingItem?.duplicated ? (
              <div style={styles.duplicatedLowRankNumber}>
                <span style={styles.duplicatedLowRankNumberText}>同率</span>
                {RankingItem?.rank}
              </div>
            ) : (
              <div style={styles.lowRankNumber}>{RankingItem?.rank}</div>
            )}
          </p>
        )}
      </div>
      <img
        className='object-cover rounded-full my-1'
        style={styles.profileImage}
        src={RankingItem?.profileImageUrl}
      />
      <div style={styles.nameContainer}>
        <p style={isTopThree ? styles.topRankPetName : styles.lowRankPetName}>
          {RankingItem?.name}
        </p>
        <p style={isTopThree ? styles.topRankPoint : styles.lowRankPoint}>
          {RankingItem?.lifeTimePoints.toFixed(1)}ポイント
        </p>
      </div>
    </div>
  );
};

const styles = {
  profileImage: { width: 60, height: 60, flexShrink: 0 },
  topRankPetName: { fontSize: 16, fontWeight: 'bold', color: colors.onSurface },
  lowRankPetName: { fontSize: 16, fontWeight: 'bold', color: colors.onSurface },
  topRankPoint: { color: colors.onSurface, fontSize: 14 },
  lowRankPoint: { color: colors.onSurface, fontSize: 14 },
  nameContainer: {
    justifyContent: 'center',
    rowGap: 5,
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 15,
    paddingRight: 15,
  },
  topRankNumber: {
    color: 'white',
    fontWeight: 'bold',
    margin: 10,
    textAlign: 'center' as 'center',
    flexShrink: 0,
    textShadow:
      '1px 1px 0 black, -1px -1px 0 black, -1px 1px 0 black, 1px -1px 0 black, 0px 1px 0 black,  0-1px 0 black, -1px 0 0 black, 1px 0 0 black',
  },
  lowRankNumber: {
    color: colors.primary,
    fontWeight: 'bold',
    margin: 10,
    textAlign: 'center' as 'center',
    flexShrink: 0,
  },
  duplicatedLowRankNumber: {
    color: colors.primary,
    fontWeight: 'bold',
    margin: 10,
    marginTop: 0,
    textAlign: 'center' as 'center',
  },
  duplicatedLowRankNumberText: {
    display: 'block',
    fontSize: 10,
    marginBottom: -2,
  },
};

export default RankingItemCard;
