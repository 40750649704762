import { useEffect, useState } from 'react';
import AdminNavbar from '../../../components/admin/AdminNavbar';
import { useAxios } from '../../../context/AxiosContext';
import { Pet } from '../../../types';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/en';

dayjs.extend(utc);
dayjs.extend(relativeTime);
dayjs.extend(timezone);

const AdminCampaignResultPage = () => {
  const { authRequest } = useAxios();
  const [pets, setPets] = useState([]);
  const [month, setMonth] = useState(4);

  //対象のデータを取得
  const getPetData = async () => {
    try {
      const res = await authRequest(`/admin/campaign-result/${month}`);
      setPets(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getPetData();
  }, [month]);

  return (
    <>
      <AdminNavbar />
      <div className='mx-auto w-11/12 pt-10 md:max-w-screen-xl'>
        <h1 className='font-bold text-2xl'>
          わんこCamにわんこを投稿してAmazonギフトカードを当てよう キャンペーン
        </h1>
        <div className='flex justify-between'>
          {/* right */}
          <div>
            <div className='flex items-center gap-6 my-6'>
              <p className='font-bold text-xl'>
                今回の対象者一覧（{pets?.length}件）
              </p>
              <p className='bg-[#F2F2F2] rounded-md py-2.5 px-6'>
                集計期間: 2024年{month}月16日〜{month + 1}月15日
              </p>
              <p className='bg-[#F2F2F2] rounded-md py-2.5 px-6'>
                当選発表日: 2024年{month + 2}月1日
              </p>
            </div>

            <div className='overflow-x-auto'>
              <table className='table'>
                {/* head */}
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Icon</th>
                    <th>わんこの名前</th>
                    <th>投稿数</th>
                    <th>同じ飼い主のエントリー</th>
                    <th>飼い主ID</th>
                    <th>メールアドレス</th>
                  </tr>
                </thead>
                <tbody>
                  {pets.map((pet: Pet) => {
                    return (
                      <tr key={pet?.id}>
                        <th>{pet?.id}</th>
                        <td>
                          <div className='avatar'>
                            <div className='w-8 md:w-12 rounded-full'>
                              <img src={pet?.profileImageUrl} />
                            </div>
                          </div>
                        </td>
                        <td>{pet.name}</td>
                        <td>{pet?.Post?.length}件</td>
                        <td>
                          {pet?.hasSameUser ? (
                            <p className='text-error'>あり</p>
                          ) : (
                            <p>なし</p>
                          )}
                        </td>
                        <td>{pet?.User?.id}</td>
                        <td>{pet?.User?.email}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              {pets.length == 0 && <p className='p-6'>対象者がいません</p>}
            </div>
          </div>

          {/* left */}
          <div className='inline-flex p-4 gap-2 flex-col items-start rounded-xl bg-[#F2F2F2] shadow-base h-fit'>
            <p className='font-bold text-lg'>期間別リンク</p>
            <div className='flex flex-col gap-7 px-4'>
              <p
                className='font-bold text-sm cursor-pointer'
                onClick={() => {
                  setMonth(4);
                }}
              >
                2024年4月16日〜5月15日
              </p>
              <p
                className='font-bold text-sm cursor-pointer'
                onClick={() => {
                  setMonth(5);
                }}
              >
                2024年5月16日〜6月15日
              </p>
              <p
                className='font-bold text-sm cursor-pointer'
                onClick={() => {
                  setMonth(6);
                }}
              >
                2024年6月16日〜7月15日
              </p>
              <p
                className='font-bold text-sm cursor-pointer'
                onClick={() => {
                  setMonth(7);
                }}
              >
                2024年7月16日〜8月15日
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminCampaignResultPage;
