import { useLocation, useNavigate } from 'react-router-dom';
import { useAxios } from '../../context/AxiosContext';
import { useEffect, useState } from 'react';
import PostCard from '../../components/post/PostCard';
import Navbar from '../../components/common/Navbar';
import Footer from '../../components/common/Footer';
import { Post } from '../../types';
import { Helmet } from 'react-helmet-async';
import { snackbar } from '../../utils/toast';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterShareButton,
  XIcon,
} from 'react-share';
import { EllipsisHorizontalIcon } from '@heroicons/react/24/solid';
import { ArrowUpOnSquareIcon } from '@heroicons/react/24/outline';

interface CustomError {
  response: {
    data: {
      message: string;
      statusCode: number;
      // 他に必要なプロパティがあれば追加
    };
  };
}
const PostDetailsPage = () => {
  const { publicRequest } = useAxios();
  // urlにあるpostIdを取得する
  const location = useLocation();
  const postId = location.pathname.split('/')[2];

  const navigate = useNavigate();

  const [post, setPost] = useState<Post | null>(null);

  //postの詳細をidで取得する
  const getPostDetailsById = async () => {
    try {
      const res = await publicRequest.get(`/posts/${postId}`);
      console.log(res.data);
      setPost(res.data);
    } catch (err) {
      if ((err as CustomError).response.data.statusCode === 400) {
        //not foundページにリダイレクトする
        navigate('/not-found');
      }
      console.log(err);
    }
  };

  useEffect(() => {
    getPostDetailsById();
  }, [postId]);

  const handleCopyClick = async () => {
    try {
      const currentUrl = window.location.href;
      await navigator.clipboard.writeText(currentUrl);
      snackbar('リンクをコピーしました！');
    } catch (error) {
      console.error('リンクのコピーに失敗しました', error);
      snackbar('リンクのコピーに失敗しました');
    }
  };

  const shareData = {
    title: 'わんこCam',
    text: `#わんこCam 「${post?.Challenge.title}」コンテストに参加しました！\n`,
    url: window.location.href,
  };

  const handleShare = async () => {
    try {
      await navigator.share(shareData);
    } catch (err) {
      console.error(`Error: ${err}`);
    }
  };

  return (
    <div className='bg-[#FFDCC2]'>
      <Helmet>
        <title>{`わんこCam - ${post?.title}`}</title>
        <meta name='description' content={post?.caption} />
      </Helmet>
      <div className='mx-auto w-11/12 pt-5 pb-20 md:max-w-screen-2xl flex flex-col items-center'>
        <img src='/assets/site-logo.png' className='w-48 mb-10' />
        <PostCard post={post} />
        <div className='bg-[#DAEC7A] py-5 px-5 rounded-lg border-2 border-[#576400] flex flex-col items-center space-y-4 w-full max-w-lg mt-10 mb-5'>
          <p className='font-[500]'>このページをシェアしましょう！</p>
          <button
            className='flex items-center bg-[#576400] text-white py-2 px-5 rounded-full font-[500]'
            onClick={handleCopyClick}
          >
            <ContentCopyIcon className='mr-1' />
            リンクをコピーする
          </button>
          <div className='flex space-x-3'>
            <TwitterShareButton
              url={window.location.href}
              title={`#わんこCam「${post?.Challenge.title}」コンテストに参加しました！\n`}
            >
              <XIcon round={true} size={40} />
            </TwitterShareButton>
            <FacebookShareButton
              url={window.location.href}
              hashtag={`#わんこCam「${post?.Challenge.title}」コンテストに参加しました！\n`}
            >
              <FacebookIcon round={true} size={40} />
            </FacebookShareButton>
            <button
              className='bg-white rounded-full p-2 flex items-center justify-center'
              onClick={handleShare}
            >
              <ArrowUpOnSquareIcon className='w-6 h-6 text-[#576400]' />
            </button>
          </div>
        </div>

        <p className='w-11/12 text-[#2E1500] max-w-lg my-5 font-[500] text-center'>
          わんこCamとは、愛犬たちが様々なコンテストに参加して、写真や動画を投稿できる新しいSNSです。
          あなたのワンちゃんも色々なコンテストに挑戦しませんか？
        </p>
        <a href={process.env.REACT_APP_APP_STORE_URL} target='_blank'>
          <img
            src='/assets/app_store_badge.svg'
            className='w-44 mt-5 min-h-[40px] h-14'
          />
        </a>
        <a href={process.env.REACT_APP_GOOGLE_PLAY_STORE_URL} target='_blank'>
          <img
            src='/assets/google_play_badge.png'
            className='w-44 my-5 min-h-[40px]'
          />
        </a>
      </div>
    </div>
  );
};

export default PostDetailsPage;
