import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react';
import { User } from '../types';
import { useAuth } from './AuthContext';

interface UserContextType {
  user: User | null;
  updateUser: (newUser: User | null) => Promise<void>;
  logout: () => Promise<void>;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

export const UserContextProvider = ({ children }: PropsWithChildren) => {
  const { updateAuthToken } = useAuth();
  const [user, setUser] = useState<User | null>(null);

  const updateUser = async (newUser: User | null) => {
    if (newUser) {
      localStorage.setItem('user', JSON.stringify(newUser));
    } else {
      localStorage.removeItem('user');
    }
    setUser(newUser);
  };

  const getLocalUser = async () => {
    try {
      //state保持のためlocalStorageに保存する
      const localUser = localStorage.getItem('user');
      if (localUser !== null) {
        const formatedUser = JSON.parse(localUser); // JSONパースする必要がある
        setUser(formatedUser);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const logout = async () => {
    updateAuthToken(null, null);
    updateUser(null);
  };
  useEffect(() => {
    getLocalUser();
  }, []);

  return (
    <UserContext.Provider value={{ user, updateUser, logout }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = (): UserContextType => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUser must be used within UserProvider');
  }
  return context;
};
