import React, { useState } from 'react';
import { useAxios } from '../../../context/AxiosContext';
import { useNavigate } from 'react-router-dom';
import { CreateMagazineFormProps } from '../../../types';
import { successToast } from '../../../utils/toast';

const CreateVideoForm: React.FC<CreateMagazineFormProps> = ({
  setIsLoading,
}) => {
  const [url, setUrl] = useState<string>('');
  const [videoId, setVideoId] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [isPublished, setIsPublished] = useState<boolean>(true);
  const [urlError, setUrlError] = useState<string>('');
  const [videoIdError, setVideoIdError] = useState<string>('');
  const [descriptionError, setDescriptionError] = useState<string>('');

  const navigate = useNavigate();

  const { authRequest } = useAxios();

  const handleSubmit = async () => {
    try {
      //validation start
      if (!url) {
        setUrlError('urlを入力してください');
      } else {
        setUrlError('');
      }
      if (!url) {
        setVideoIdError('YouTube動画IDを入力してください');
      } else {
        setVideoIdError('');
      }

      if (!description) {
        setDescriptionError('説明文を入力してください');
      } else {
        setDescriptionError('');
      }
      if (!url || !description || !videoId) {
        return;
      }
      //validation end

      setIsLoading(true);
      // 作成を実行
      await authRequest.post('/magazines/video', {
        url,
        videoId,
        description,
        isPublished,
      });
      setIsLoading(false);
      navigate('/admin/magazines/video');
      successToast('マガジンが作成されました');
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  const handleDescriptionChange = (text: string) => {
    // 入力文字数が70文字を超えた場合にエラーを設定
    if (text.length > 70) {
      setDescription(text.slice(0, 70));
      setDescriptionError('70文字以内で入力してください');
    } else {
      // 入力値を更新
      setDescription(text);
      setDescriptionError('');
    }
  };

  return (
    <div className="mt-5 w-full max-w-md flex flex-col">
      <div className="form-control w-full max-w-md">
        <label className="label">
          <span className="label-text">YouTube動画 URL</span>
          <span className="label-text-alt">required</span>
        </label>
        <input
          type="text"
          placeholder="YouTube動画のurl"
          className={`input input-bordered w-full max-w-md ${
            urlError && 'input-error'
          }`}
          onChange={(e) => setUrl(e.target.value)}
          value={url}
          required
        />
        {/* error用 */}
        <label className="label">
          <span className="label-text-alt text-red-500 font-semibold">
            {urlError}
          </span>
        </label>
      </div>

      <div className="form-control w-full max-w-md">
        <label className="label">
          <span className="label-text">YouTube動画 ID</span>
          <span className="label-text-alt">required</span>
        </label>
        <input
          type="text"
          placeholder="youtube.com/watch?v=<ID> or youtube.com/shorts/<ID>"
          className={`input input-bordered w-full max-w-md ${
            videoIdError && 'input-error'
          }`}
          onChange={(e) => setVideoId(e.target.value)}
          value={videoId}
          required
        />
        <span className="label-text pt-2">
          例:https://www.youtube.com/watch?v=<b>c7LOZjWUWMM</b>
          ←この太字の部分です。
        </span>
        {/* error用 */}
        <label className="label">
          <span className="label-text-alt text-red-500 font-semibold">
            {videoIdError}
          </span>
        </label>
      </div>

      <div className="form-control mt-5">
        <label className="label">
          <span className="label-text">説明文</span>
          <span className="label-text-alt">required</span>
        </label>
        <textarea
          className={`textarea textarea-bordered h-24 ${
            descriptionError && 'textarea-error'
          }`}
          placeholder="この記事に対する説明文"
          value={description}
          onChange={(e) => handleDescriptionChange(e.target.value)}
          required
        ></textarea>
        <label className="label">
          <span className="label-text-alt text-red-500 font-semibold">
            {descriptionError}
          </span>
        </label>
      </div>
      <div className="form-control mt-5">
        <label className="label">
          <span className="label-text">公開設定</span>
          <span className="label-text-alt">required</span>
        </label>
        <div className="flex items-center space-x-3 mt-2">
          <input
            type="checkbox"
            className="toggle"
            checked={isPublished}
            onChange={() => setIsPublished(!isPublished)}
          />
          <span>{isPublished ? '公開' : '非公開'}</span>
        </div>
      </div>
      <div className="flex self-end mt-5 space-x-5">
        <button
          onClick={() => navigate('/admin/magazines/article')}
          className="btn"
        >
          キャンセル
        </button>
        <button onClick={handleSubmit} className="btn btn-primary">
          投稿
        </button>
      </div>
    </div>
  );
};

export default CreateVideoForm;
