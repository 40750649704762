import {
  CheckCircleIcon,
  InformationCircleIcon,
} from '@heroicons/react/24/outline';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';

export const successToast = (message: string) =>
  toast.custom(
    (t) => (
      <div
        className='alert alert-success  gap-2 !bg-emerald-400 p-3 shadow-lg md:w-1/2 md:gap-4 md:p-4'
        style={{ display: 'flex' }}
      >
        <CheckCircleIcon className='h-6 w-6' />
        <span>{message}</span>
      </div>
    ),
    { position: 'bottom-center' }
  );

export const snackbar = (message: string) =>
  toast.custom(
    (t) => (
      <div
        className='alert alert-success rounded-lg text-[#FAEEE8]  gap-2 !bg-[#352F2B] p-3 shadow-lg md:w-1/2 md:gap-4 md:p-4'
        style={{ display: 'flex' }}
      >
        <span>{message}</span>
      </div>
    ),
    { position: 'bottom-center' }
  );

export const successToastWithButton = (message: string) =>
  toast.custom(
    (t) => (
      <div
        className='alert alert-success !bg-emerald-400 shadow-lg md:w-1/2'
        style={{ display: 'flex' }}
      >
        <div className='flex items-center'>
          <CheckCircleIcon className='h-6 w-6' />
          <span>{message}</span>
        </div>
        <button onClick={() => toast.dismiss(t.id)} className='btn-sm btn '>
          Ok
        </button>
      </div>
    ),
    { position: 'bottom-center' }
  );

export const errorToast = (message: string) =>
  toast.custom(
    (t) => (
      <div
        className='alert alert-error gap-2 !bg-[#FF4E16] p-3 shadow-lg md:w-1/2 md:gap-4 md:p-4'
        style={{ display: 'flex' }}
      >
        <InformationCircleIcon className='h-6 w-6' />
        <span>{message}</span>
      </div>
    ),
    { position: 'bottom-center' }
  );

export const ErrorToastBlock = (message: string) => {
  return (
    <div className='alert alert-error w-full !bg-[#FF4E16] shadow-lg '>
      <div className='flex items-center'>
        <InformationCircleIcon className='h-10 w-10' />
        <span className='md:text-md w-full text-sm'>{message}</span>
      </div>
    </div>
  );
};
