import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

const EmailVerificationPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get('token');

  const [verificationStatus, setVerificationStatus] = useState('PENDING');

  // トークンをバックエンドのAPIに送信して認証を行うリクエストを作成
  // 認証ステータスを設定
  const confirmEmail = async () => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_BASEURL}/auth/email-confirm`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setVerificationStatus('SUCCESS');
      console.log('res', res);
    } catch (err) {
      //失効していた場合はsigninに戻す
      setVerificationStatus('FAILED');
      console.log(err);
    }
  };

  useEffect(() => {
    if (token) {
      confirmEmail();
    }
  }, [token]);

  return (
    <div>
      <h1>Email Verification</h1>
      <p>Verification Status: {verificationStatus}</p>
    </div>
  );
};

export default EmailVerificationPage;
