import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAxios } from '../../../context/AxiosContext';
import { CreateMagazineFormProps } from '../../../types';
import { successToast } from '../../../utils/toast';

const CreateChallengeForm: React.FC<CreateMagazineFormProps> = ({
  setIsLoading,
}) => {
  const [title, setTitle] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [isPublished, setIsPublished] = useState<boolean>(true);
  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);
  const [titleError, setTitleError] = useState<string>('');
  const [descriptionError, setDescriptionError] = useState<string>('');
  const [file, setFile] = useState<File | null>(null);
  const [fileError, setFileError] = useState<string | null>('');
  const [startDateError, setStartDateError] = useState<string | null>('');
  const [endDateError, setEndDateError] = useState<string | null>('');
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [mediaType, setMediaType] = useState<string>('ALL');

  const navigate = useNavigate();

  const { authRequest } = useAxios();

  const handleSubmit = async () => {
    try {
      //validation start
      if (!title) {
        setTitleError('タイトルを入力してください');
      } else {
        setTitleError('');
      }

      if (!description) {
        setDescriptionError('説明文を入力してください');
      } else {
        setDescriptionError('');
      }

      if (!file) {
        setFileError('画像ファイルを選択してください');
      } else {
        setFileError('');
      }
      if (!startDate) {
        setStartDateError('開始日を選択してください');
      } else {
        setStartDateError('');
      }
      if (!endDate) {
        setEndDateError('終了日を選択してください');
      } else {
        setEndDateError('');
      }

      if (!title || !description || !file || !startDate || !endDate) {
        return;
      }

      //validation end

      setIsLoading(true);

      //s3にアップロード
      const s3ObjectPath = await authRequest.post(
        '/s3/challenge-image',
        {
          file,
        },
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      // 作成を実行
      await authRequest.post('/challenges', {
        title,
        description,
        imageUrl: s3ObjectPath.data,
        startDate: new Date(startDate),
        endDate: new Date(endDate),
        isPublished,
        mediaType,
      });
      setIsLoading(false);
      navigate('/admin/challenges');
      successToast('コンテストが作成されました');
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  // ファイルがアップロードされた時の処理
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];

    if (selectedFile) {
      if (selectedFile.type.startsWith('image/')) {
        setFile(selectedFile);
        setFileError(null);

        // 画像ファイルの場合、プレビューを表示
        const reader = new FileReader();
        reader.readAsDataURL(selectedFile);
        reader.onload = () => {
          setImagePreview(reader.result as string);
        };
      } else {
        setFile(null);
        setFileError('画像ファイルを選択してください。');
        setImagePreview(null);
      }
    } else {
      setFile(null);
      setFileError('ファイルを選択してください。');
      setImagePreview(null);
    }
  };

  return (
    <div className='mt-5 w-full max-w-md flex flex-col'>
      <div className='form-control w-full'>
        <label className='label'>
          <span className='label-text'>サムネイル画像</span>
          <span className='label-text-alt'>required</span>
        </label>
        <input
          type='file'
          className={`file-input file-input-bordered w-full ${
            fileError && 'file-input-error'
          }`}
          onChange={handleFileChange}
        />
        <label className='label'>
          <span className='label-text-alt text-red-500'>{fileError}</span>
        </label>
        {imagePreview && (
          <div className=''>
            <p>プレビュー</p>
            <img
              src={imagePreview}
              className='my-3 h-64 object-cover rounded-lg w-full'
              alt='プレビュー'
            />
          </div>
        )}
      </div>
      <div className='form-control w-full max-w-md'>
        <label className='label'>
          <span className='label-text'>タイトル</span>
          <span className='label-text-alt'>required</span>
        </label>
        <input
          type='text'
          placeholder='コンテストのタイトル'
          className={`input input-bordered w-full max-w-md ${
            titleError && 'input-error'
          }`}
          onChange={(e) => setTitle(e.target.value)}
          value={title}
          required
        />
        {/* error用 */}
        <label className='label'>
          <span className='label-text-alt text-red-500 font-semibold'>
            {titleError}
          </span>
        </label>
      </div>

      <div className='form-control mt-5'>
        <label className='label'>
          <span className='label-text'>説明文</span>
          <span className='label-text-alt'>required</span>
        </label>
        <textarea
          className={`textarea textarea-bordered h-24 ${
            descriptionError && 'textarea-error'
          }`}
          placeholder='この記事に対する説明文'
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          required
        ></textarea>
        <label className='label'>
          <span className='label-text-alt text-red-500 font-semibold'>
            {descriptionError}
          </span>
        </label>
      </div>
      <div className='form-control mt-5'>
        <label className='label'>
          <span className='label-text'>期間</span>
          <span className='label-text-alt'>required</span>
        </label>

        <div className='flex space-x-3 items-center'>
          <input
            type='date'
            placeholder='記事のurl'
            className={`input input-bordered w-full max-w-md ${
              startDateError && 'input-error'
            }`}
            onChange={(e) => setStartDate(e.target.value)}
            value={startDate ? startDate : ''}
            required
            max={endDate ? endDate : ''}
          />
          <p>-</p>
          <input
            type='date'
            placeholder='記事のurl'
            className={`input input-bordered w-full max-w-md ${
              endDateError && 'input-error'
            }`}
            onChange={(e) => setEndDate(e.target.value)}
            value={endDate ? endDate : ''}
            required
            min={startDate ? startDate : ''}
          />
        </div>

        <label className='label'>
          <span className='label-text-alt text-red-500 font-semibold'>
            {startDateError}
          </span>
          <span className='label-text-alt text-red-500 font-semibold'>
            {endDateError}
          </span>
        </label>
      </div>

      <div className='form-control mt-5'>
        <label className='label mb-3'>
          <span className='label-text'>許可するメディア</span>
          <span className='label-text-alt'>required</span>
        </label>

        <div className='form-control mb-3'>
          <label className='items-center flex space-x-5 cursor-pointer'>
            <input
              type='radio'
              name='radio-10'
              className='radio checked:bg-primary'
              checked={mediaType == 'ALL'}
              onClick={() => setMediaType('ALL')}
            />
            <span className='label-text'>全て</span>
          </label>
        </div>
        <div className='form-control mb-3'>
          <label className='items-center flex space-x-5 cursor-pointer'>
            <input
              type='radio'
              name='radio-10'
              className='radio checked:bg-primary'
              checked={mediaType == 'IMAGE'}
              onClick={() => setMediaType('IMAGE')}
            />
            <span className='label-text'>写真のみ</span>
          </label>
        </div>
        <div className='form-control mb-3'>
          <label className='items-center flex space-x-5 cursor-pointer'>
            <input
              type='radio'
              name='radio-10'
              className='radio checked:bg-primary'
              checked={mediaType == 'VIDEO'}
              onClick={() => setMediaType('VIDEO')}
            />
            <span className='label-text'>動画のみ</span>
          </label>
        </div>
      </div>

      <div className='form-control mt-5'>
        <label className='label'>
          <span className='label-text'>公開設定</span>
          <span className='label-text-alt'>required</span>
        </label>
        <div className='flex items-center space-x-3 mt-2'>
          <input
            type='checkbox'
            className='toggle'
            checked={isPublished}
            onChange={() => setIsPublished(!isPublished)}
          />
          <span>{isPublished ? '公開' : '非公開'}</span>
        </div>
      </div>
      <div className='flex self-end mt-5 space-x-5'>
        <button onClick={() => navigate('/admin/challenges')} className='btn'>
          キャンセル
        </button>
        <button onClick={handleSubmit} className='btn btn-primary'>
          投稿
        </button>
      </div>
    </div>
  );
};

export default CreateChallengeForm;
