import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react';

import { useAuth } from './AuthContext';
import { Admin } from '../types';

interface AdminContextType {
  admin: Admin | null;
  updateAdmin: (newAdmin: Admin | null) => Promise<void>;
  adminLogout: () => Promise<void>;
}

const AdminContext = createContext<AdminContextType | undefined>(undefined);

export const AdminContextProvider = ({ children }: PropsWithChildren) => {
  const { updateAuthToken } = useAuth();
  const [admin, setAdmin] = useState<Admin | null>(null);

  const updateAdmin = async (newAdmin: Admin | null) => {
    if (newAdmin) {
      localStorage.setItem('admin', JSON.stringify(newAdmin));
    } else {
      localStorage.removeItem('admin');
    }
    setAdmin(newAdmin);
  };

  const getLocalAdmin = async () => {
    try {
      //state保持のためlocalStorageに保存する
      const localAdmin = localStorage.getItem('admin');
      if (localAdmin !== null) {
        console.log('get admin');
        const formatedUser = JSON.parse(localAdmin); // JSONパースする必要がある
        setAdmin(formatedUser);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const adminLogout = async () => {
    updateAuthToken(null, null);
    updateAdmin(null);
  };
  useEffect(() => {
    getLocalAdmin();
  }, []);

  return (
    <AdminContext.Provider value={{ admin, updateAdmin, adminLogout }}>
      {children}
    </AdminContext.Provider>
  );
};

export const useAdmin = (): AdminContextType => {
  const context = useContext(AdminContext);
  if (context === undefined) {
    throw new Error('useAdmin must be used within AdminProvider');
  }
  return context;
};
