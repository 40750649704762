import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';

const Home = () => {
  return (
    <div>
      {/* section1 start */}
      <div className='bg-[#FFA857] p-10 lg:px-20  lg:py-[7.5rem] h-full flex flex-col  justify-center'>
        <div className=' flex flex-col lg:flex-row justify-between items-center  max-w-5xl mx-auto w-full'>
          {/* left */}
          <div className='flex flex-col items-center lg:items-start mx-auto  max-w-xl'>
            <img
              src='/assets/phrase/desktop/top.png'
              className='w-[18.75rem] hidden lg:block mb-14'
            />

            <div className='text-[#000] font-bold text-sm lg:text-2xl lg:flex-col flex'>
              <p className='font-mPlusRounded font-bold text-xl lg:text-4xl'>
                あなたの愛犬が主役！
                <br />
                新しいわんこ専用SNS
              </p>
            </div>

            <img
              src='/assets/site-logo.png'
              className='w-64 lg:w-[32rem] mb-6 lg:mb-14'
            />

            <img
              src='/assets/phrase/mobile/top.png'
              className='w-48 lg:hidden mb-6'
            />
            <div className='flex space-x-5 items-center'>
              <a href={process.env.REACT_APP_APP_STORE_URL} target='_blank'>
                <img
                  src='/assets/app_store_badge.svg'
                  className='!hidden w-44 lg:!flex min-h-[40px] h-14'
                />
              </a>
              <a
                href={process.env.REACT_APP_GOOGLE_PLAY_STORE_URL}
                target='_blank'
              >
                <img
                  src='/assets/google_play_badge.png'
                  className='!hidden w-44 lg:!flex min-h-[40px]'
                />
              </a>
            </div>
          </div>
          {/* right */}
          <div className='flex flex-col space-y-3 items-center mx-auto'>
            <img src='/assets/lp-screenshot-1.png' className='w-80 lg:w-96' />
            <a href={process.env.REACT_APP_APP_STORE_URL} target='_blank'>
              <img
                src='/assets/app_store_badge.svg'
                className='w-44 lg:!hidden min-h-[40px] h-14'
              />
            </a>
            <a
              href={process.env.REACT_APP_GOOGLE_PLAY_STORE_URL}
              target='_blank'
            >
              <img
                src='/assets/google_play_badge.png'
                className='w-44 lg:!hidden min-h-[40px]'
              />
            </a>
          </div>
        </div>
      </div>

      {/* section1 end */}

      {/* section2 start */}

      <div className='bg-yellowSm lg:bg-yellowLg bg-cover py-8 px-4 lg:p-20'>
        <h2 className='text-[#904D00] text-3xl lg:text-5xl font-mPlusRounded font-bold mx-auto mb-5 text-center max-w-md lg:max-w-full'>
          コンテストに参加する
        </h2>
        <div className=' flex flex-col lg:flex-row justify-between items-center  max-w-md lg:max-w-5xl mx-auto space-y-5 lg:space-y-0'>
          {/* left */}
          <div className='flex flex-col items-start space-y-3 lg:items-start mx-auto px-3  max-w-xl'>
            <div className='flex items-center space-x-2'>
              <img src='/assets/dog2.png' className='w-12 lg:w-24' />
              <div className='chat chat-start'>
                <div className='chat-bubble chat-bubble-primary text-base lg:text-xl max-w-[12rem]  lg:max-w-[14rem] font-bold pt-3 font-mPlusRounded'>
                  簡単に参加できる!
                </div>
              </div>
            </div>
            <p className='font-mPlusRounded font-bold text-2xl lg:text-4xl'>
              参加するのは君だ！
            </p>
            <p className='font-mPlusRounded font-medium text-base lg:text-2xl'>
              さまざまなコンテストに君も挑戦！
              <br />
              それぞれのお題に沿った写真や動画を投稿しよう。
            </p>
          </div>
          {/* right */}
          <div className='flex flex-col space-y-3 items-center mx-auto'>
            <img src='/assets/lp-screenshot-2.png' className='w-80 lg:w-96' />
          </div>
        </div>
        <img
          src='/assets/phrase/desktop/1.png'
          className='w-96 hidden lg:block mx-auto'
        />
        <img
          src='/assets/phrase/mobile/1.png'
          className='w-96 lg:hidden mx-auto mt-6'
        />
      </div>

      {/* section2 end */}

      {/* section3 start */}

      <div className='bg-pinkSm lg:bg-pinkLg bg-cover py-8 px-4 lg:p-20'>
        <h2 className='text-[#964168] text-3xl lg:text-5xl font-mPlusRounded font-bold  mx-auto mb-5 text-center max-w-md lg:max-w-full'>
          わんこを撮影＆投稿
        </h2>
        <div className=' flex flex-col lg:flex-row justify-between items-center  max-w-md lg:max-w-5xl mx-auto space-y-5 lg:space-y-0'>
          {/* left */}
          <div className='flex flex-col items-start space-y-3 lg:items-start mx-auto px-3 max-w-xl'>
            <div className='flex items-center space-x-2'>
              <img src='/assets/dog3.png' className='w-12 lg:w-24' />
              <div className='chat chat-start'>
                <div className='chat-bubble chat-bubble-secondary text-base lg:text-xl max-w-[20rem] lg:max-w-[25rem] font-bold pt-3 font-mPlusRounded'>
                  君のチャームポイントを自慢しよう
                </div>
              </div>
            </div>
            <p className='font-mPlusRounded font-bold text-2xl lg:text-4xl'>
              とっておきの写真や動画をシェア
            </p>
            <p className='font-mPlusRounded font-medium text-base lg:text-2xl'>
              これまでに撮影してもらった写真や動画、アプリ内カメラで撮影してもらった偶然の一瞬、お気に入りの写真や動画をシェアしよう！
            </p>
          </div>
          {/* right */}
          <div className='flex flex-col space-y-3 items-center mx-auto'>
            <img src='/assets/lp-screenshot-3.png' className='w-80 lg:w-96' />
          </div>
        </div>
        <img
          src='/assets/phrase/desktop/2.png'
          className='w-96 hidden lg:block mx-auto'
        />
        <img
          src='/assets/phrase/mobile/2.png'
          className='w-96 lg:hidden mx-auto mt-6'
        />
      </div>

      {/* section3 end */}

      {/* section4 start */}

      <div className='bg-yellowSm lg:bg-yellowLg bg-cover py-8 px-4 lg:p-20'>
        <h2 className='text-[#904D00] text-3xl lg:text-5xl font-mPlusRounded font-bold  mx-auto mb-5 text-center  max-w-md lg:max-w-full'>
          ポジティブなコミュニティにようこそ
        </h2>
        <div className='flex flex-col lg:flex-row justify-between items-center  max-w-md lg:max-w-5xl mx-auto space-y-5 lg:space-y-0'>
          {/* left */}
          <div className='flex flex-col items-start space-y-3 lg:items-start mx-auto px-3 max-w-xl'>
            <div className='flex items-center space-x-2'>
              <img src='/assets/dog4.png' className='w-12 lg:w-24' />
              <div className='chat chat-start'>
                <div className='chat-bubble chat-bubble-primary text-base lg:text-xl max-w-[11.5rem] lg:max-w-[14rem] font-bold pt-3 font-mPlusRounded'>
                  ストレスフリー！
                </div>
              </div>
            </div>
            <p className='font-mPlusRounded font-bold text-2xl lg:text-4xl'>
              わんこ愛を世界中に広めよう！
            </p>
            <p className='font-mPlusRounded font-medium text-base lg:text-2xl'>
              コメントやDMの機能は無くて、とってもシンプル。
              <br />
              君の心を動かしたわんこに、いいねを贈ろう！
              <br />
              素敵なわんこと友達になれるかも！？
            </p>
          </div>
          {/* right */}
          <div className='flex flex-col space-y-3 items-center mx-auto'>
            <img src='/assets/lp-screenshot-4.png' className='w-80 lg:w-96' />
          </div>
        </div>
        <img
          src='/assets/phrase/desktop/3.png'
          className='w-96 hidden lg:block mx-auto'
        />
        <img
          src='/assets/phrase/mobile/3.png'
          className='w-96 lg:hidden mx-auto mt-6'
        />
      </div>

      {/* section4 end */}

      {/* section5 start */}

      <div className='bg-pinkSm lg:bg-pinkLg bg-cover py-8 px-4 lg:p-20'>
        <h2 className='text-[#964168] text-3xl lg:text-5xl font-mPlusRounded font-bold  mx-auto mb-5 text-center max-w-md lg:max-w-full'>
          わんこが集まるコミュニティに参加しよう
        </h2>
        <div className=' flex flex-col lg:flex-row justify-between items-center max-w-md lg:max-w-5xl mx-auto space-y-5 lg:space-y-0'>
          {/* left */}
          <div className='flex flex-col items-start space-y-3 lg:items-start mx-auto px-3  max-w-xl'>
            <div className='flex items-center space-x-2'>
              <img src='/assets/dog5.png' className='w-12 lg:w-24' />
              <div className='chat chat-start'>
                <div className='chat-bubble chat-bubble-secondary text-base lg:text-xl max-w-[19rem] lg:max-w-[23.2rem] font-bold pt-3 font-mPlusRounded '>
                  いろんなコンテストに参加しよう
                </div>
              </div>
            </div>
            <p className='font-mPlusRounded font-bold text-2xl lg:text-4xl'>
              目指せわんこCamナンバーワン！
            </p>
            <p className='font-mPlusRounded font-medium text-base lg:text-2xl'>
              各コンテストが終わると、ランキングの発表があるんだ。
              <br />
              たくさんのいいねを集めたわんこが表彰台に！
              <br />
              君もランキングトップを目指そう。
            </p>
          </div>
          {/* right */}
          <div className='flex flex-col space-y-3 items-center mx-auto'>
            <img src='/assets/lp-screenshot-5.png' className='w-80 lg:w-96' />
          </div>
        </div>
        <img
          src='/assets/phrase/desktop/4.png'
          className='w-96 hidden lg:block mx-auto'
        />
        <img
          src='/assets/phrase/mobile/4.png'
          className='w-96 lg:hidden mx-auto mt-6'
        />
      </div>

      {/* section5 end */}
      {/* section6 start */}

      <div className='bg-greenSm lg:bg-greenLg bg-cover pt-20 pb-36 px-4 lg:p-20 lg:pb-40'>
        <h2 className='text-[#576400] text-3xl lg:text-5xl font-mPlusRounded font-bold  mx-auto mb-5 text-center max-w-md lg:max-w-full'>
          紹介されました！
        </h2>
        <div className=' flex flex-col lg:flex-row justify-between items-center max-w-md lg:max-w-5xl mx-auto space-y-5 lg:space-y-0'>
          {/* left */}
          <div className='flex flex-col items-start lg:items-start mx-auto px-3  max-w-xl'>
            <p className='font-bold text-[#201B17] font-mPlusRounded text-xl lg:text-2xl'>
              2024年4月27日
            </p>
            <p className='font-mPlusRounded font-bold text-2xl lg:text-4xl'>
              VOIX bizに「わんこCam」が掲載されました
            </p>
          </div>
          {/* right */}
          <div className='flex flex-col items-center mx-auto'>
            <img
              src='/assets/voix_logo.png'
              className='w-40 mx-auto bg-white lg:w-60'
            />
            <img
              src='/assets/voix_screenshot.png'
              className='w-72 mx-auto mt-6 lg:w-96'
            />
            <a
              href={'https://voix.jp/biz/news/118136/'}
              target='_blank'
              className='flex-inline items-center w-72 lg:w-96 text-center underline text-xs lg:text-sm font-medium font-mPlusRounded text-[#576400]'
            >
              Yuyosoft Innovations
              Inc.が愛犬家専用ソーシャルメディアアプリ「わんこCam」をリリース
              <ArrowTopRightOnSquareIcon className='text-[#576400] w-[0.8rem] h-[0.8rem] inline-block ml-1 mb-0.5' />
            </a>
          </div>
        </div>
        <div className=' flex flex-col lg:flex-row justify-between items-center max-w-md lg:max-w-5xl mx-auto mt-20 space-y-5 lg:space-y-0'>
          {/* left */}
          <div className='flex flex-col items-start lg:items-start mx-auto lg:px-3  max-w-xl'>
            <p className='font-bold text-[#201B17] font-mPlusRounded text-xl lg:text-2xl'>
              2024年4月27日
            </p>
            <p className='font-mPlusRounded font-bold text-2xl lg:text-4xl'>
              NIKKEI COMPASSに「わんこCam」が掲載されました
            </p>
          </div>
          {/* right */}
          <div className='flex flex-col items-center mx-auto'>
            <a
              href={
                'https://www.nikkei.com/compass/content/PRTKDB000000001_000141977/preview'
              }
              target='_blank'
              className='flex-inline items-center lg:w-[25rem] text-center lg:text-left underline text-base lg:text-xl font-medium font-mPlusRounded text-[#576400]'
            >
              意外にも海外在住の日本人チームが開発！愛犬家に話題のソーシャルメディアアプリ『わんこCam』［Yuyosoft
              Innovations Inc.］ | NIKKEI COMPASS - 日本経済新聞
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                strokeWidth='2.5'
                stroke='currentColor'
                className='text-[#576400] w-[1.4rem] h-[1.4rem] lg:w-[1.6rem] lg:h-[1.6rem] inline-block ml-2 mb-1'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25'
                />
              </svg>
            </a>
          </div>
        </div>
      </div>

      {/* section6 end */}

      {/* section7 start */}
      <div className='bg-[#904D00] flex flex-col items-center py-8 px-4 lg:p-20'>
        <div className='flex flex-col lg:flex-row items-center lg:space-x-20 space-y-5 lg:space-y-0'>
          {/* left */}
          <div>
            <img
              src='/assets/site-logo-white.png'
              className='w-56 lg:w-72 mb-5'
            />
            <img src='/assets/download_text.png' className='w-56 lg:w-72' />
          </div>
          {/* right */}
          <img src='/assets/wanko_icon.png' className='w-40' />
        </div>
        <div className='flex flex-col  mt-6 lg:mt-14 space-y-5 lg:flex-row lg:space-y-0 lg:space-x-5 lg:items-center'>
          <a href={process.env.REACT_APP_APP_STORE_URL} target='_blank'>
            <img
              src='/assets/app_store_badge.svg'
              className='w-44 min-h-[40px] h-14'
            />
          </a>
          <a href={process.env.REACT_APP_GOOGLE_PLAY_STORE_URL} target='_blank'>
            <img
              src='/assets/google_play_badge.png'
              className='w-44 min-h-[40px]'
            />
          </a>
        </div>
      </div>

      {/* section7 end */}

      {/* section8 start */}
      <div className='bg-[#F3DFD2] py-20 flex flex-col items-center'>
        <div className='flex pt-6'>
          <a href='https://twitter.com/wancocam' target='_blank'>
            <img
              className='w-10 mx-5'
              src='/assets/x_icon.png'
              alt='X アカウント'
            />
          </a>
          <a href='https://www.instagram.com/wancocam/' target='_blank'>
            <img
              className='w-10 mx-5'
              src='/assets/insta_icon.png'
              alt='X アカウント'
            />
          </a>
          <a
            href='https://www.facebook.com/profile.php?id=61552478456908'
            target='_blank'
          >
            <img
              className='w-10 mx-5'
              src='/assets/fb_icon.png'
              alt='X アカウント'
            />
          </a>
        </div>
        <p className='my-10 w-[70%] lg:w-full text-center text-sm text-[#51443B]'>
          わんこCamは、カナダと東京を拠点に展開しているYuyosoft Innovations
          Inc.がプロデュースしている、愛犬家のためのわんこ専用SNSです
        </p>
        <a
          href='https://yuyosoft.com/'
          target='_blank'
          rel='noopener noreferrer'
          className='mb-5 text-[#51443B] text-sm underline cursor-pointer'
        >
          Yuyosoft Innovations Inc.
        </a>
        <p className='text-[#51443B] text-sm text-center'>
          Contents copyright, all rights reserved by Yuyosoft Innovations Inc.
        </p>
      </div>

      {/* section8 end */}
    </div>
  );
};

export default Home;
