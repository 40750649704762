import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAxios } from '../../context/AxiosContext';
import { useEffect, useState } from 'react';
import RankingItemCard from '../../components/RankingItemCard';
import { Pet, RankingItem } from '../../types';
import { Helmet } from 'react-helmet-async';
import {
  ArrowUpOnSquareIcon,
  EllipsisVerticalIcon,
} from '@heroicons/react/24/outline';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { snackbar } from '../../utils/toast';
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterShareButton,
  XIcon,
} from 'react-share';
interface CustomError {
  response: {
    data: {
      message: string;
      statusCode: number;
      // 他に必要なプロパティがあれば追加
    };
  };
}

const LifeTimeRankingPage = () => {
  const { publicRequest } = useAxios();
  // urlにあるpostIdを取得する
  const [searchParams] = useSearchParams();
  const petId = searchParams.get('petId');

  const navigate = useNavigate();
  const [ranking, setRanking] = useState<RankingItem[]>([]);
  const [pet, setPet] = useState<RankingItem | null>(null);
  const [isRanked, setIsRanked] = useState<boolean>(false);
  const [rank, setRank] = useState<number | null>(null);

  //postの詳細をidで取得する
  const getRanking = async () => {
    try {
      const res = await publicRequest.get('/pets/lifetime-points', {
        params: {
          page: 1,
          perPage: 10,
        },
      });
      const data = detectDuplicatedRank(res.data);

      setRanking(data);

      if (petId) {
        //petIdでペット情報を順位付きで取得する
        const petData = await publicRequest.get(
          `/pets/lifetime-points/pets/${petId}`
        );
        setPet(petData.data);
        setRank(petData.data.rank);

        //取得した10個のランキングに含まれているかチェック
        const isPetIncluded = res.data.some(
          (pet: Pet) => pet.id === petData.data.id
        );
        setIsRanked(isPetIncluded);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const detectDuplicatedRank = (array: any) => {
    const itemIds = array.map(function (item: any) {
      return item.rank;
    });

    const newArray = array.map((item: any, index: number) => {
      if (
        itemIds.indexOf(item.rank) !== index ||
        itemIds.includes(item.rank, index + 1)
      ) {
        item.duplicated = true;
      } else item.duplicated = false;
      return item;
    });

    return newArray;
  };

  useEffect(() => {
    getRanking();
  }, [petId]);

  const handleCopyClick = async () => {
    try {
      const currentUrl = window.location.href;
      await navigator.clipboard.writeText(currentUrl);
      snackbar('リンクをコピーしました！');
    } catch (error) {
      console.error('リンクのコピーに失敗しました', error);
      snackbar('リンクのコピーに失敗しました');
    }
  };

  const shareData = {
    title: 'わんこCam',
    text: `#わんこCam に参加中！ライフタイムランキングは現在${rank}位\n`,
    url: window.location.href,
  };

  const handleShare = async () => {
    try {
      await navigator.share(shareData);
    } catch (err) {
      console.error(`Error: ${err}`);
    }
  };

  return (
    <div className='bg-[#FFDDC0]'>
      <Helmet>
        <title>『わんこCam』ライフタイムランキング確認ページ</title>
        <meta
          name='description'
          content='アプリ『わんこCam』内、コンテストのライフタイムランキングをこちらで確認できます。自分のランキングも確認できます'
        />
      </Helmet>
      <div className='mx-auto w-11/12 pt-5 pb-20 md:max-w-screen-2xl flex flex-col items-center'>
        <img src='/assets/site-logo.png' className='w-40 md:w-64 mb-10' />
        <h1 className='justify-between md:px-10 text-xl text-black md:text-xl'>
          ライフタイムポイントランキング
        </h1>
        <div
          style={{ width: '100%', maxWidth: 380 }}
          className='items-center flex flex-col'
        >
          {ranking?.map((item: any, index: number) => {
            return (
              <RankingItemCard RankingItem={item} petId={petId} key={index} />
            );
          })}
          {/* petidで取得したpetが11位以降の場合表示 */}
          {pet && !isRanked && (
            <>
              <EllipsisVerticalIcon className='w-12 h-12 text-[#D6C3B6]' />
              <RankingItemCard RankingItem={pet} petId={petId} />
            </>
          )}
        </div>
        {petId && (
          <div className='bg-[#DAEC7A] py-5 px-5 rounded-lg border-2 border-[#576400] flex flex-col items-center space-y-4 w-full max-w-sm mt-10 mb-5'>
            <p className='font-[500]'>このページをシェアしましょう！</p>
            <button
              className='flex items-center bg-[#576400] text-white py-2 px-5 rounded-full font-[500]'
              onClick={handleCopyClick}
            >
              <ContentCopyIcon className='mr-1' />
              リンクをコピーする
            </button>
            <div className='flex space-x-3'>
              <TwitterShareButton
                url={window.location.href}
                title={`#わんこCam に参加中！ライフタイムランキングは現在${rank}位\n`}
              >
                <XIcon round={true} size={40} />
              </TwitterShareButton>
              <FacebookShareButton
                url={window.location.href}
                hashtag={`#わんこCam に参加中！ライフタイムランキングは現在${rank}位\n`}
              >
                <FacebookIcon round={true} size={40} />
              </FacebookShareButton>
              <button
                className='bg-white rounded-full p-2 flex items-center justify-center'
                onClick={handleShare}
              >
                <ArrowUpOnSquareIcon className='w-6 h-6 text-[#576400]' />
              </button>
            </div>
          </div>
        )}

        <p className='w-11/12 text-[#000] max-w-sm  my-5 font-[500]'>
          わんこCamとは、愛犬たちが様々なコンテストに参加して、写真や動画を投稿できる新しいSNSです。
          あなたのワンちゃんも色々なコンテストに挑戦しませんか？
        </p>
        <a href={process.env.REACT_APP_APP_STORE_URL} target='_blank'>
          <img
            src='/assets/app_store_badge.svg'
            className='w-44 mt-5 min-h-[40px] h-14'
          />
        </a>
        <a href={process.env.REACT_APP_GOOGLE_PLAY_STORE_URL} target='_blank'>
          <img
            src='/assets/google_play_badge.png'
            className='w-44 my-5 min-h-[40px]'
          />
        </a>
      </div>
    </div>
  );
};

export default LifeTimeRankingPage;
