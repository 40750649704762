import React, { useEffect, useState } from 'react';
import AdminNavbar from '../../../components/admin/AdminNavbar';
import { useAxios } from '../../../context/AxiosContext';
import { RecommendedVideo } from '../../../types';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/en';
import { Link } from 'react-router-dom';
import DeleteVideoModal from '../../../components/admin/magazine/DeleteVideoModal';

dayjs.extend(relativeTime);

const AdminVideoMagazinesPage = () => {
  const { authRequest } = useAxios();
  const [recommendedVideos, setRecommendedVideos] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState<RecommendedVideo | null>(
    null
  );

  //全てのおすすめyoutube動画を取得
  const getAllRecommendedVideos = async () => {
    try {
      const res = await authRequest('/magazines/video');
      setRecommendedVideos(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getAllRecommendedVideos();
  }, []);

  return (
    <>
      <AdminNavbar />
      <div className="mx-auto w-11/12 pt-10 md:max-w-screen-2xl">
        <h1 className="font-bold text-2xl">おすすめYouTube動画一覧</h1>
        <Link
          to={'/admin/magazines/create?type=video'}
          className="btn btn-primary my-5"
        >
          新規作成
        </Link>

        <div className="overflow-x-auto">
          <table className="table">
            {/* head */}
            <thead>
              <tr>
                <th>ID</th>
                <th>サムネイル</th>
                <th>タイトル</th>
                <th>ステータス</th>
                <th>作成日</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {recommendedVideos.map((video: RecommendedVideo) => (
                <tr key={video?.id}>
                  <th>{video.id}</th>
                  <td>
                    <img
                      src={
                        'https://img.youtube.com/vi/' + video.videoId + '/1.jpg'
                      }
                    />
                  </td>
                  <td>{video.url}</td>
                  <td>{video.isPublished ? '公開中' : '非公開'}</td>
                  <td>{dayjs(video?.createdAt).format('YYYY-MM-DD')}</td>
                  <td>
                    <Link
                      to={`/admin/magazines/video/edit/${video.id}`}
                      className="btn btn-secondary mr-3"
                    >
                      編集
                    </Link>
                    <button
                      onClick={() => setSelectedVideo(video)}
                      className="btn"
                    >
                      削除
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {selectedVideo && (
        <DeleteVideoModal
          video={selectedVideo}
          setSelectedVideo={setSelectedVideo}
          getAllRecommendedVideos={getAllRecommendedVideos}
        />
      )}
    </>
  );
};

export default AdminVideoMagazinesPage;
