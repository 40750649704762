import { useState } from 'react';
import AdminNavbar from '../../../components/admin/AdminNavbar';

import LoadingScreen from '../../../components/LoadingScreen';
import CreateChallengeForm from '../../../components/admin/challenge/CreateChallengeForm';

const AdminChallengeCreatePage = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  return (
    <div>
      <AdminNavbar />
      <div className='mx-auto w-11/12 pt-10 md:max-w-screen-2xl flex flex-col items-center'>
        <div className='w-full max-w-md mx-auto'>
          <h1 className='font-bold text-2xl'>コンテスト作成</h1>
        </div>
        <CreateChallengeForm setIsLoading={setIsLoading} />
      </div>
      {isLoading && <LoadingScreen />}
    </div>
  );
};

export default AdminChallengeCreatePage;
