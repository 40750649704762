import React, { ChangeEvent, useEffect, useState } from 'react';
import AdminNavbar from '../../../components/admin/AdminNavbar';
import LoadingScreen from '../../../components/LoadingScreen';

import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAxios } from '../../../context/AxiosContext';
import { Faq, FaqCategory } from '../../../types';
import { successToast } from '../../../utils/toast';

import SimpleMDE from 'react-simplemde-editor';
import 'easymde/dist/easymde.min.css';
import { marked } from 'marked';
import DOMPurify from 'dompurify';
import ReactMarkdown from 'react-markdown';

const AdminFaqEditPage = () => {
  const [categoryId, setCategoryId] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isAnswerChanged, setIsAnswerChanged] = useState<boolean>(false);
  const [faqCategories, setFaqCategories] = useState<FaqCategory[]>([]);

  const location = useLocation();
  const faqId = location.pathname.split('/')[4];

  // select要素の変更時に呼び出される関数
  const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setCategoryId(parseInt(event.target.value));
  };

  const [question, setQuestion] = useState<string>('');
  const [answer, setAnswer] = useState('');
  const [markdownValue, setMarkdownValue] = useState('');
  const [answerForRender, setAnswerForRender] = useState('');
  const [previousAnswer, setPreviousAnswer] = useState('');
  const [isPublished, setIsPublished] = useState<boolean>(true);
  const [questionError, setQuestionError] = useState<string>('');
  const [markdownValueError, setMarkdownValueError] = useState<string>('');
  const [categoryError, setCategoryError] = useState<string>('');

  const navigate = useNavigate();

  const { authRequest, publicRequest } = useAxios();

  const handleSubmit = async () => {
    try {
      //validation start
      if (!question) {
        setQuestionError('サイトタイトルを入力してください');
      } else {
        setQuestionError('');
      }

      if (!markdownValue) {
        setMarkdownValueError('回答を入力してください');
      } else {
        setMarkdownValueError('');
      }

      if (!categoryId) {
        setCategoryError('カテゴリを選択してください');
      } else {
        setCategoryError('');
      }

      if (!question || !markdownValue || !categoryId) {
        return;
      }

      //validation end

      setIsLoading(true);

      // 作成を実行
      await authRequest.patch(`/faq/${faqId}`, {
        question,
        answer: isAnswerChanged ? answer : previousAnswer,
        markdownAnswer: markdownValue,
        categoryId,
        isPublished,
        isActive: true,
        // order: 1,
      });
      navigate('/admin/faq');
      successToast('FAQが保存されました');
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const getFaqCategories = async () => {
    try {
      const { data } = await publicRequest.get('/faq/category');
      setFaqCategories(data);
    } catch (err) {
      console.log(err);
    }
  };

  const getFaq = async () => {
    try {
      const { data }: { data: Faq } = await publicRequest.get(`/faq/${faqId}`);
      setCategoryId(data.category.id);
      setQuestion(data.question);
      setIsPublished(data.isPublished);
      setAnswer(data.markdownAnswer);
      setMarkdownValue(data.markdownAnswer);
      setPreviousAnswer(data.answer);
      onChangeHandler(data.markdownAnswer, false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getFaqCategories();
    getFaq();
  }, []);

  const onChangeHandler = async (value: any, isChanged: boolean) => {
    setIsAnswerChanged(isChanged);
    // markedのrendererオプションを設定
    const renderer = new marked.Renderer();
    marked.setOptions({ breaks: true });

    // h1要素にクラスを追加
    renderer.heading = function (text, level) {
      let result = '';
      switch (level) {
        case 1:
          result = `<h1 class="text-5xl font-bold">${text}</h1>`;
          break;
        case 2:
          result = `<h2 class="text-4xl font-bold">${text}</h2>`;
          break;
        case 3:
          result = `<h3 class="text-3xl font-bold">${text}</h3>`;
          break;
        case 4:
          result = `<h4 class="text-2xl font-bold">${text}</h4>`;
          break;
        case 5:
          result = `<h5 class="text-xl font-bold">${text}</h5>`;
          break;
        case 6:
          result = `<h5 class="text-lg font-bold">${text}</h5>`;
          break;

        default:
          result = `<h${level} class="text-lg font-bold">${text}</h${level}>`;
          break;
      }
      return result;
    };

    // li要素にクラスを追加
    renderer.list = function (body, order, number) {
      if (order) {
        return `<ol class='list-decimal ml-5'>${body}</ol>`;
      } else {
        return `<ul class='list-disc ml-5'>${body}</ul>`;
      }
    };

    // リンクはaタグに変換
    renderer.link = function (href, title, text) {
      return `<a href="${href}" title="${
        title || ''
      }" target="_blank" class='underline text-blue-500'>${text}</a>`;
    };

    // markedのbreaksオプションを有効にする
    const options = {
      breaks: false,
    };

    // markedにrendererを指定
    const markedValueForRender = await marked(value, { renderer, ...options });
    const markedValue = await marked(value, options);
    if (isChanged) {
      setAnswer(markedValue);
      setMarkdownValue(value);
    }
    setAnswerForRender(markedValueForRender);
  };

  return (
    <div>
      <AdminNavbar />
      <div className='mx-auto w-11/12 pt-10 pb-10 md:max-w-screen-2xl flex flex-col items-center'>
        <div className='w-full max-w-md mx-auto'>
          <h1 className='font-bold text-2xl'>よくある質問　編集</h1>
        </div>

        {/* form */}
        <div className='mt-5 w-full max-w-md flex flex-col'>
          <div className='form-control w-full max-w-md'>
            <label className='label'>
              <span className='label-text'>質問</span>
              <span className='label-text-alt'>required</span>
            </label>
            <input
              type='text'
              placeholder='（例）わんこCamとはどんなアプリですか'
              className={`input input-bordered w-full max-w-md ${
                questionError && 'input-error'
              }`}
              onChange={(e) => setQuestion(e.target.value)}
              value={question}
              required
            />
            {/* error用 */}
            <label className='label'>
              <span className='label-text-alt text-red-500 font-semibold'>
                {questionError}
              </span>
            </label>
          </div>

          <div className='form-control mt-5'>
            <label className='label'>
              <span className='label-text'>回答</span>
              <span className='label-text-alt'>required</span>
            </label>

            <SimpleMDE
              value={answer}
              onChange={(value) => onChangeHandler(value, true)}
            />

            <p className='label-text my-3'>プレビュー</p>
            <div className='border p-3 rounded-md' id='body'>
              <div
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(answerForRender),
                }}
              ></div>
            </div>
            <p className='mt-5'>
              ※改行を挿入したい場合、以下のように入力する
              <ReactMarkdown>
                {'1行目のテキスト。<br>2行目のテキスト'}
              </ReactMarkdown>
            </p>
            <label className='label'>
              <span className='label-text-alt text-red-500 font-semibold'>
                {markdownValueError}
              </span>
            </label>
          </div>

          <div className='form-control mt-5'>
            <label className='label'>
              <span className='label-text'>カテゴリ</span>
              <span className='label-text-alt'>required</span>
            </label>

            <select
              className='select select-bordered w-full mt-5'
              value={categoryId}
              onChange={handleSelectChange}
            >
              {faqCategories.map((category) => (
                <option value={category.id}>{category.name}</option>
              ))}
            </select>

            <p className='text-sm mt-3'>
              ※ 適切なカテゴリがない場合は
              <Link
                to={'/admin/faq/category/create'}
                className='underline font-semibold'
              >
                カテゴリ追加ページ
              </Link>
              から追加してください
            </p>
          </div>

          <div className='form-control mt-5'>
            <label className='label'>
              <span className='label-text'>公開設定</span>
              <span className='label-text-alt'>required</span>
            </label>
            <div className='flex items-center space-x-3 mt-2'>
              <input
                type='checkbox'
                className='toggle'
                checked={isPublished}
                onChange={() => setIsPublished(!isPublished)}
              />
              <span>{isPublished ? '公開' : '非公開'}</span>
            </div>
          </div>
          <div className='flex self-end mt-5 space-x-5'>
            <button onClick={() => navigate('/admin/faq')} className='btn'>
              キャンセル
            </button>
            <button onClick={handleSubmit} className='btn btn-primary'>
              投稿
            </button>
          </div>
        </div>
      </div>
      {isLoading && <LoadingScreen />}
    </div>
  );
};

export default AdminFaqEditPage;
