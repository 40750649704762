import React, { ChangeEvent, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import AdminNavbar from '../../../components/admin/AdminNavbar';
import CreateArticleForm from '../../../components/admin/magazine/CreateArticleForm';
import CreateVideoForm from '../../../components/admin/magazine/CreateVideoForm';
import CreateStoreForm from '../../../components/admin/magazine/CreateStoreForm';
import LoadingScreen from '../../../components/LoadingScreen';

const AdminMagazineCreatePage = () => {
  const [postType, setPostType] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const type = searchParams.get('type');

  // select要素の変更時に呼び出される関数
  const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setPostType(event.target.value);
  };

  useEffect(() => {
    if (type !== null) setPostType(type);
  }, []);

  return (
    <div>
      <AdminNavbar />
      <div className='mx-auto w-11/12 pt-10 pb-10 md:max-w-screen-2xl flex flex-col items-center'>
        <div className='w-full max-w-md mx-auto'>
          <h1 className='font-bold text-2xl'>マガジン投稿</h1>
          <select
            className='select select-bordered w-full max-w-sm mt-5'
            value={postType}
            onChange={handleSelectChange}
          >
            <option value={'article'}>おすすめペット記事</option>
            <option value={'video'}>おすすめyoutube</option>
            <option value={'store'}>おすすめペット関連ショップ</option>
          </select>
        </div>
        {/* 選択したオプションに応じてformを切り替える */}
        {postType == 'article' && (
          <CreateArticleForm setIsLoading={setIsLoading} />
        )}
        {postType == 'video' && <CreateVideoForm setIsLoading={setIsLoading} />}
        {postType == 'store' && <CreateStoreForm setIsLoading={setIsLoading} />}
      </div>
      {isLoading && <LoadingScreen />}
    </div>
  );
};

export default AdminMagazineCreatePage;
