import React, { useEffect, useState } from 'react';
import AdminNavbar from '../../../components/admin/AdminNavbar';
import { useAxios } from '../../../context/AxiosContext';
import { CustomError } from '../../../types';
import { useNavigate } from 'react-router-dom';

const AdminPostSearchPage = () => {
  const [postId, setPostId] = useState<string>('');
  const [error, setError] = useState<string>('');

  const { publicRequest } = useAxios();

  const navigate = useNavigate();

  //post検索実行関数
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const res = await publicRequest.get(`/posts/${postId}`);
      console.log(res.data);
      navigate(`/admin/posts/${postId}`);
    } catch (err) {
      console.log(err);
      console.log((err as CustomError).response.data.message);
      if ((err as CustomError).response.data.statusCode === 400) {
        setError('投稿が見つかりませんでした');
      } else {
        setError('エラーが発生しました。もう一度実行してください');
      }
    }
  };

  return (
    <>
      <AdminNavbar />
      <div className='mx-auto w-11/12 pt-10 md:max-w-screen-2xl'>
        <h1 className='font-bold text-2xl'>ポスト削除</h1>
        <form className='mt-5' onSubmit={handleSubmit}>
          <div className='form-control w-full max-w-md'>
            <label className='label'>
              <span className='label-text font-semibold'>
                Search By Post ID
              </span>
            </label>
            <div className='flex items-center space-x-4'>
              <input
                type='text'
                placeholder='post IDを入力してください'
                className={`input input-bordered w-full max-w-xs ${
                  error && 'input-error'
                }`}
                value={postId}
                onChange={(e) => setPostId(e.target.value)}
              />
              <button type='submit' className='btn btn-primary'>
                検索
              </button>
            </div>
            <label className='label'>
              <span className='label-text-alt text-error'>{error}</span>
            </label>
          </div>
        </form>
      </div>
    </>
  );
};

export default AdminPostSearchPage;
