import { XMarkIcon } from '@heroicons/react/24/outline';
import React from 'react';

import { RemovePostFromPickupModalProps } from '../../../types';

const RemovePostFromPickupModal: React.FC<RemovePostFromPickupModalProps> = ({
  post,
  setSelectedPost,
  handleSubmit,
}) => {
  return (
    <>
      <div
        onClick={(e) => {
          e.stopPropagation();
          setSelectedPost(null);
        }}
        className=' fixed top-0 left-0 z-[1001] h-full w-full bg-black bg-opacity-50'
      ></div>
      <div
        className='fixed top-1/2 left-1/2 z-[1001]  flex  w-10/12 -translate-x-1/2 -translate-y-1/2  transform rounded-xl bg-white px-6 py-5 md:!w-[600px]'
        id='cancel-modal-container'
      >
        <div
          className='relative h-full w-full flex-1'
          id='cancel-modal-content'
        >
          <div className='mt-3 flex flex-col items-center'>
            <h2 className='font-bold text-2xl mb-5'>
              このポストをリストから削除しますか？
            </h2>

            <img
              className='w-32 h-32 object-cover'
              src={
                post.mediaType == 'IMAGE'
                  ? post.mediaUrl
                  : post.mediumThumbnailUrl
              }
            />
            <p className='my-5'>{post.title}</p>
            <div className='flex space-x-5 mt-5'>
              <button className='btn' onClick={() => setSelectedPost(null)}>
                キャンセル
              </button>
              <button
                type='submit'
                className='btn btn-primary'
                onClick={handleSubmit}
              >
                削除する
              </button>
            </div>
          </div>
          <button
            onClick={() => setSelectedPost(null)}
            className='btn-sm btn-circle btn absolute -right-2 -top-2'
          >
            <XMarkIcon className='h-5 w-5' />
          </button>
        </div>
      </div>
    </>
  );
};

export default RemovePostFromPickupModal;
