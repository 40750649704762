import React, { useEffect, useState } from 'react';
import AdminNavbar from '../../../components/admin/AdminNavbar';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAxios } from '../../../context/AxiosContext';
import LoadingScreen from '../../../components/LoadingScreen';
import { successToast } from '../../../utils/toast';

const AdminArticleMagazineEditPage = () => {
  const [url, setUrl] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [siteTitle, setSiteTitle] = useState<string>('');
  const [siteImageUrl, setSiteImageUrl] = useState<string>('');
  const [isPublished, setIsPublished] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [urlError, setUrlError] = useState<string>('');
  const [descriptionError, setDescriptionError] = useState<string>('');
  const [siteTitleError, setSiteTitleError] = useState<string>('');
  const [siteImageUrlError, setSiteImageUrlError] = useState<string>('');

  const [file, setFile] = useState<File | null>(null);
  const [fileError, setFileError] = useState<string | null>('');
  const [imagePreview, setImagePreview] = useState<string | null>(null);

  const navigate = useNavigate();

  const { authRequest } = useAxios();

  const location = useLocation();
  const articleId = location.pathname.split('/')[5];

  const getRecommendedArticleById = async () => {
    try {
      const res = await authRequest.get(`/magazines/article/${articleId}`);
      setUrl(res.data.url);
      setDescription(res.data.description);
      setSiteTitle(res.data.siteTitle);
      setSiteImageUrl(res.data.siteImageUrl);
      setIsPublished(res.data.isPublished);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getRecommendedArticleById();
  }, [articleId]);

  const handleSubmit = async () => {
    try {
      // validation start
      if (!url) {
        setUrlError('urlを入力してください');
      } else {
        setUrlError('');
      }
      if (!siteImageUrl) {
        setSiteImageUrlError('サイト画像URLを入力してください');
      } else {
        setSiteImageUrlError('');
      }
      if (!siteTitle) {
        setSiteTitleError('サイトタイトルを入力してください');
      } else {
        setSiteTitleError('');
      }

      if (!description) {
        setDescriptionError('説明文を入力してください');
      } else {
        setDescriptionError('');
      }

      if (!url || !description || !siteImageUrl || !siteTitle) {
        return;
      }

      // validation end
      setIsLoading(true);

      // ファイルを更新した場合、s3にアップロードしオブジェクトpathを取得
      let s3ObjectPath = null;
      if (file) {
        const s3Response = await authRequest.post(
          '/s3/magazine-image',
          {
            file,
            magazineType: 'article',
          },
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        );
        s3ObjectPath = s3Response.data;
      }

      // 更新を実行
      await authRequest.patch(`/magazines/article/${articleId}`, {
        url,
        description,
        siteTitle,
        siteImageUrl: s3ObjectPath ? s3ObjectPath : siteImageUrl,
        isPublished,
      });

      setIsLoading(false);

      navigate('/admin/magazines/article');
      successToast('正常にコンテンツが保存されました');
    } catch (err) {
      setIsLoading(false);

      console.log(err);
    }
  };

  // ファイルをアップロードした時の処理
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];

    if (selectedFile) {
      if (selectedFile.type.startsWith('image/')) {
        setFile(selectedFile);
        setFileError(null);

        // 画像ファイルの場合、プレビューを表示
        const reader = new FileReader();
        reader.readAsDataURL(selectedFile);
        reader.onload = () => {
          setImagePreview(reader.result as string);
        };
      } else {
        setFile(null);
        setFileError('画像ファイルを選択してください。');
        setImagePreview(null);
      }
    } else {
      setFile(null);
      setImagePreview(null);
      if (!siteImageUrl) {
        setFileError('ファイルを選択してください。');
      }
    }
  };

  const handleDescriptionChange = (text: string) => {
    // 入力文字数が70文字を超えた場合にエラーを設定
    if (text.length > 70) {
      setDescription(text.slice(0, 70));
      setDescriptionError('70文字以内で入力してください');
    } else {
      // 入力値を更新
      setDescription(text);
      setDescriptionError('');
    }
  };

  return (
    <div>
      <AdminNavbar />
      <div className="mx-auto w-11/12 pt-10 md:max-w-screen-2xl flex flex-col pb-10">
        <h1 className="font-bold text-2xl">おすすめペット記事編集</h1>

        <div className="mt-5 w-full flex space-x-8">
          {/* card */}
          <div className="card w-[500px] h-1/3  bg-base-100 shadow-xl">
            <figure className="h-48">
              <img
                src={imagePreview ? imagePreview : siteImageUrl}
                alt="Site Image"
              />
            </figure>
            <div className="card-body">
              <h2 className="card-title text-sm text-[#904D00]">
                おすすめコンテンツ
              </h2>
              <h2 className="card-title font-normal text-sm text-[#964168]">
                （画像は{siteTitle}より）
              </h2>
              <p className="text-xs">{description}</p>
            </div>
          </div>
          {/* form */}
          <div className="w-full">
            <div className="form-control">
              <label className="label">
                <span className="label-text">公開設定</span>
                <span className="label-text-alt">required</span>
              </label>
              <div className="flex items-center space-x-3 mt-2">
                <input
                  type="checkbox"
                  className="toggle"
                  checked={isPublished}
                  onChange={() => setIsPublished(!isPublished)}
                />
                <span>{isPublished ? '公開' : '非公開'}</span>
              </div>
            </div>
            <div className="form-control w-full  mt-5">
              <label className="label">
                <span className="label-text">ペットSNS/記事 URL</span>
                <span className="label-text-alt">required</span>
              </label>
              <input
                type="text"
                placeholder="記事のurl"
                className={`input input-bordered w-full ${
                  urlError && 'input-error'
                }`}
                onChange={(e) => setUrl(e.target.value)}
                value={url}
              />
              <label className="label">
                <span className="label-text-alt text-red-500 font-semibold">
                  {urlError}
                </span>
              </label>
            </div>

            <div className="form-control w-full">
              <label className="label">
                <span className="label-text">サムネイル画像</span>
                <span className="label-text-alt">required</span>
              </label>

              <label className="btn max-w-xs mt-3" htmlFor="fileSelect">
                画像ファイルを変更する
              </label>
              <input
                id="fileSelect"
                type="file"
                className="hidden"
                onChange={handleFileChange}
              />
              {file && (
                <>
                  <p className="my-3">選択された画像</p>
                  <p>{file?.name}</p>
                </>
              )}

              <label className="label">
                <span className="label-text-alt text-red-500">{fileError}</span>
              </label>
            </div>

            <div className="form-control w-full mt-5">
              <label className="label">
                <span className="label-text">サイトタイトル</span>
                <span className="label-text-alt">required</span>
              </label>
              <input
                type="text"
                placeholder="サイトタイトル"
                className={`input input-bordered w-full ${
                  siteTitleError && 'input-error'
                }`}
                onChange={(e) => setSiteTitle(e.target.value)}
                value={siteTitle}
              />
              <label className="label">
                <span className="label-text-alt text-red-500 font-semibold">
                  {siteTitleError}
                </span>
              </label>
            </div>

            <div className="form-control mt-5">
              <label className="label">
                <span className="label-text">説明文</span>
                <span className="label-text-alt">required</span>
              </label>
              <textarea
                className={`textarea textarea-bordered h-24 ${
                  descriptionError && 'textarea-error'
                }`}
                placeholder="この記事に対する説明文"
                value={description}
                onChange={(e) => handleDescriptionChange(e.target.value)}
              ></textarea>
              <label className="label">
                <span className="label-text-alt text-red-500 font-semibold">
                  {descriptionError}
                </span>
              </label>
            </div>

            <div className="flex self-end mt-5 space-x-5">
              <button
                onClick={() => navigate('/admin/magazines/article')}
                className="btn"
              >
                キャンセル
              </button>
              <button onClick={handleSubmit} className="btn btn-primary">
                保存
              </button>
            </div>
          </div>

          {/* form end */}
        </div>
      </div>
      {isLoading && <LoadingScreen />}
    </div>
  );
};

export default AdminArticleMagazineEditPage;
