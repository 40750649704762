import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import Home from './pages/Home';
import EmailVerificationPage from './pages/auth/EmailVerificationPage';
import NewEmailVerificationPage from './pages/auth/NewEmailVerificationPage';
import ResetPasswordPage from './pages/auth/ResetPasswordPage';
import ResetPasswordSuccessPage from './pages/auth/ResetPasswordSuccessPage';
import ResetPasswordFailPage from './pages/auth/ResetPasswordFailPage';
import { AuthContextProvider } from './context/AuthContext';
import { UserContextProvider } from './context/UserContext';
import { AxiosContextProvider, useAxios } from './context/AxiosContext';
import AdminLoginPage from './pages/admin/AdminLoginPage';
import { AdminContextProvider, useAdmin } from './context/AdminContext';
import AdminMagazineCreatePage from './pages/admin/magazine/AdminMagazineCreatePage';
import AdminArticleMagazinesPage from './pages/admin/magazine/AdminArticleMagazinesPage';
import AdminArticleMagazineEditPage from './pages/admin/magazine/AdminArticleMagazineEditPage';
import { Toaster } from 'react-hot-toast';
import AdminStoreMagazineEditPage from './pages/admin/magazine/AdminStoreMagazineEditPage';
import AdminStoreMagazinesPage from './pages/admin/magazine/AdminStoreMagazinesPage';
import AdminVideoMagazinesPage from './pages/admin/magazine/AdminVideoMagazinesPage';
import AdminVideoMagazineEditPage from './pages/admin/magazine/AdminVideoMagazineEditPage';

import AdminChallengeListPage from './pages/admin/challenge/AdminChallengeListPage';
import AdminChallengeCreatePage from './pages/admin/challenge/AdminMagazineCreatePage';
import AdminChallengeEditPage from './pages/admin/challenge/AdminChallengeEditPage';

import PostDetailsPage from './pages/posts/PostDetailsPage';
import NotFoundPage from './pages/NotFoundPage';
import AdminUserSearchPage from './pages/admin/users/AdminUserSearchPage';
import AdminUserDetailsPage from './pages/admin/users/AdminUserDetailsPage';
import AdminPostSearchPage from './pages/admin/posts/AdminPostSearchPage';
import AdminPostDetailsPage from './pages/admin/posts/AdminPostDetailsPage';
import LifeTimeRankingPage from './pages/ranking/LifeTimeRankingPage.tsx';
import ReactGA from 'react-ga4';
import { useEffect } from 'react';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import TermsOfUsePage from './pages/TermsOfUsePage';
import AppPrivacyPolicyPage from './pages/AppPrivacyPolicyPage';
import AdminFaqListPage from './pages/admin/faq/AdminFaqListPage';
import AdminFaqCreatePage from './pages/admin/faq/AdminFaqCreatePage';
import AdminFaqCategoryCreatePage from './pages/admin/faq/AdminCategoryCreatePage';
import AdminFaqEditPage from './pages/admin/faq/AdminFaqEditPage';
import PickupPostListPage from './pages/admin/pickup/PickupPostListPage';
import AdminCampaignResultPage from './pages/admin/campaign/AdminCampaignResultPage';
import { AdminDashboardPage } from './pages/admin/AdminDashboardPage';

const AdminRoutes = () => {
  const { admin } = useAdmin();
  const { authRequest } = useAxios();

  const localAdmin = localStorage.getItem('admin');
  const navigate = useNavigate();
  //セッションチェック用のapiを叩く
  const sessionCheck = async () => {
    try {
      await authRequest.get('/admin/session-check');
    } catch (err) {
      console.log(err);
    }
  };

  if (admin) {
    sessionCheck();
  }

  useEffect(() => {
    // adminがnullになった場合にリダイレクト
    if (!admin) {
      navigate('/admin/login');
    }
  }, [admin]);

  if (!admin && !localAdmin) {
    return <Navigate to='/admin/login' />;
  }
  return <Outlet />;
};

function InnerComponent() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (ReactGA.isInitialized) {
      console.log('ga init done');
      ReactGA.send({
        hitType: 'pageview',
        page: location.pathname,
        title: location.pathname,
      });
    } else {
      console.log('ga not init yet');
    }
  }, [location]);
  return null;
}

function App() {
  return (
    <AuthContextProvider>
      <AdminContextProvider>
        <UserContextProvider>
          <AxiosContextProvider>
            <BrowserRouter>
              <InnerComponent />
              <Routes>
                <Route path='/' element={<Home />} />
                <Route
                  path='/email-verification'
                  element={<EmailVerificationPage />}
                />
                <Route
                  path='/new-email-verification'
                  element={<NewEmailVerificationPage />}
                />
                <Route path='/reset-password' element={<ResetPasswordPage />} />
                <Route
                  path='/reset-password-success'
                  element={<ResetPasswordSuccessPage />}
                />
                <Route
                  path='/reset-password-fail'
                  element={<ResetPasswordFailPage />}
                />
                <Route path='/posts/:id' element={<PostDetailsPage />} />
                <Route path='/ranking' element={<LifeTimeRankingPage />} />

                <Route path='/privacy' element={<PrivacyPolicyPage />} />
                <Route path='/privacy/app' element={<AppPrivacyPolicyPage />} />
                <Route path='/terms' element={<TermsOfUsePage />} />
                <Route path='/not-found' element={<NotFoundPage />} />
                <Route path='/admin/login' element={<AdminLoginPage />} />
                <Route element={<AdminRoutes />}>
                  <Route
                    path='/admin/magazines/create'
                    element={<AdminMagazineCreatePage />}
                  />
                  <Route
                    path='/admin/magazines/article'
                    element={<AdminArticleMagazinesPage />}
                  />
                  <Route
                    path='/admin/magazines/article/edit/:id'
                    element={<AdminArticleMagazineEditPage />}
                  />
                  <Route
                    path='/admin/magazines/store'
                    element={<AdminStoreMagazinesPage />}
                  />
                  <Route
                    path='/admin/magazines/store/edit/:id'
                    element={<AdminStoreMagazineEditPage />}
                  />
                  <Route
                    path='/admin/magazines/video'
                    element={<AdminVideoMagazinesPage />}
                  />
                  <Route
                    path='/admin/magazines/video/edit/:id'
                    element={<AdminVideoMagazineEditPage />}
                  />

                  <Route
                    path='/admin/users'
                    element={<AdminUserSearchPage />}
                  />
                  <Route
                    path='/admin/users/:id'
                    element={<AdminUserDetailsPage />}
                  />
                  <Route
                    path='/admin/posts'
                    element={<AdminPostSearchPage />}
                  />
                  <Route
                    path='/admin/posts/:id'
                    element={<AdminPostDetailsPage />}
                  />

                  <Route
                    path='/admin/challenges'
                    element={<AdminChallengeListPage />}
                  />
                  <Route
                    path='/admin/challenges/create'
                    element={<AdminChallengeCreatePage />}
                  />
                  <Route
                    path='/admin/challenges/edit/:id'
                    element={<AdminChallengeEditPage />}
                  />
                  <Route path='/admin/faq' element={<AdminFaqListPage />} />
                  <Route
                    path='/admin/faq/create'
                    element={<AdminFaqCreatePage />}
                  />
                  <Route
                    path='/admin/faq/edit/:id'
                    element={<AdminFaqEditPage />}
                  />
                  <Route
                    path='/admin/faq/category/create'
                    element={<AdminFaqCategoryCreatePage />}
                  />
                  <Route
                    path='/admin/pickup'
                    element={<PickupPostListPage />}
                  />
                  <Route
                    path='/admin/campaign'
                    element={<AdminCampaignResultPage />}
                  />
                  <Route
                    path='/admin'
                    element={<AdminDashboardPage />}
                  />
                </Route>
                <Route path='*' element={<NotFoundPage />} />
              </Routes>
              <Toaster />
            </BrowserRouter>
          </AxiosContextProvider>
        </UserContextProvider>
      </AdminContextProvider>
    </AuthContextProvider>
  );
}

export default App;
