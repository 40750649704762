import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAxios } from '../../../context/AxiosContext';
import { CreateMagazineFormProps } from '../../../types';
import { successToast } from '../../../utils/toast';

const CreateArticleForm: React.FC<CreateMagazineFormProps> = ({
  setIsLoading,
}) => {
  const [siteTitle, setSiteTitle] = useState<string>('');
  const [url, setUrl] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [isPublished, setIsPublished] = useState<boolean>(true);
  const [siteTitleError, setSiteTitleError] = useState<string>('');
  const [urlError, setUrlError] = useState<string>('');
  const [descriptionError, setDescriptionError] = useState<string>('');
  const [file, setFile] = useState<File | null>(null);
  const [fileError, setFileError] = useState<string | null>('');
  const [imagePreview, setImagePreview] = useState<string | null>(null);

  const navigate = useNavigate();

  const { authRequest } = useAxios();

  const handleSubmit = async () => {
    try {
      //validation start
      if (!siteTitle) {
        setSiteTitleError('サイトタイトルを入力してください');
      } else {
        setSiteTitleError('');
      }

      if (!url) {
        setUrlError('urlを入力してください');
      } else {
        setUrlError('');
      }

      if (!description) {
        setDescriptionError('説明文を入力してください');
      } else {
        setDescriptionError('');
      }

      if (!file) {
        setFileError('画像ファイルを選択してください');
      } else {
        setFileError('');
      }

      if (!siteTitle || !url || !description || !file) {
        return;
      }

      //validation end

      setIsLoading(true);

      //s3にアップロード
      const s3ObjectPath = await authRequest.post(
        '/s3/magazine-image',
        {
          file,
          magazineType: 'article',
        },
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      // 作成を実行
      await authRequest.post('/magazines/article', {
        url,
        siteTitle,
        description,
        siteImageUrl: s3ObjectPath.data,
        isPublished,
      });
      setIsLoading(false);
      navigate('/admin/magazines/article');
      successToast('マガジンが作成されました');
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  // ファイルがアップロードされた時の処理
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];

    if (selectedFile) {
      if (selectedFile.type.startsWith('image/')) {
        setFile(selectedFile);
        setFileError(null);

        // 画像ファイルの場合、プレビューを表示
        const reader = new FileReader();
        reader.readAsDataURL(selectedFile);
        reader.onload = () => {
          setImagePreview(reader.result as string);
        };
      } else {
        setFile(null);
        setFileError('画像ファイルを選択してください。');
        setImagePreview(null);
      }
    } else {
      setFile(null);
      setFileError('ファイルを選択してください。');
      setImagePreview(null);
    }
  };

  const handleDescriptionChange = (text: string) => {
    // 入力文字数が70文字を超えた場合にエラーを設定
    if (text.length > 70) {
      setDescription(text.slice(0, 70));
      setDescriptionError('70文字以内で入力してください');
    } else {
      // 入力値を更新
      setDescription(text);
      setDescriptionError('');
    }
  };

  return (
    <div className='mt-5 w-full max-w-md flex flex-col'>
      <div className='form-control w-full'>
        <label className='label'>
          <span className='label-text'>サムネイル画像</span>
          <span className='label-text-alt'>required</span>
        </label>
        <input
          type='file'
          className={`file-input file-input-bordered w-full ${
            fileError && 'file-input-error'
          }`}
          onChange={handleFileChange}
        />
        <label className='label'>
          <span className='label-text-alt text-red-500'>{fileError}</span>
        </label>
        {imagePreview && (
          <div className=''>
            <p>プレビュー</p>
            <img
              src={imagePreview}
              className='my-3 h-64 object-cover rounded-lg w-full'
              alt='プレビュー'
            />
          </div>
        )}
      </div>
      <div className='form-control w-full max-w-md'>
        <label className='label'>
          <span className='label-text'>サイトタイトル</span>
          <span className='label-text-alt'>required</span>
        </label>
        <input
          type='text'
          placeholder='サイトタイトル'
          className={`input input-bordered w-full max-w-md ${
            siteTitleError && 'input-error'
          }`}
          onChange={(e) => setSiteTitle(e.target.value)}
          value={siteTitle}
          required
        />
        {/* error用 */}
        <label className='label'>
          <span className='label-text-alt text-red-500 font-semibold'>
            {siteTitleError}
          </span>
        </label>
      </div>

      <div className='form-control w-full max-w-md'>
        <label className='label'>
          <span className='label-text'>ペットSNS/記事 URL</span>
          <span className='label-text-alt'>required</span>
        </label>
        <input
          type='text'
          placeholder='記事のurl'
          className={`input input-bordered w-full max-w-md ${
            urlError && 'input-error'
          }`}
          onChange={(e) => setUrl(e.target.value)}
          value={url}
          required
        />
        {/* error用 */}
        <label className='label'>
          <span className='label-text-alt text-red-500 font-semibold'>
            {urlError}
          </span>
        </label>
      </div>

      <div className='form-control mt-5'>
        <label className='label'>
          <span className='label-text'>説明文</span>
          <span className='label-text-alt'>required</span>
        </label>
        <textarea
          className={`textarea textarea-bordered h-24 ${
            descriptionError && 'textarea-error'
          }`}
          placeholder='この記事に対する説明文'
          value={description}
          onChange={(e) => handleDescriptionChange(e.target.value)}
          required
          // maxLength={70}
        ></textarea>

        <label className='label'>
          <span className='label-text-alt text-red-500 font-semibold'>
            {descriptionError}
          </span>
        </label>
      </div>
      <div className='form-control mt-5'>
        <label className='label'>
          <span className='label-text'>公開設定</span>
          <span className='label-text-alt'>required</span>
        </label>
        <div className='flex items-center space-x-3 mt-2'>
          <input
            type='checkbox'
            className='toggle'
            checked={isPublished}
            onChange={() => setIsPublished(!isPublished)}
          />
          <span>{isPublished ? '公開' : '非公開'}</span>
        </div>
      </div>
      <div className='flex self-end mt-5 space-x-5'>
        <button
          onClick={() => navigate('/admin/magazines/article')}
          className='btn'
        >
          キャンセル
        </button>
        <button onClick={handleSubmit} className='btn btn-primary'>
          投稿
        </button>
      </div>
    </div>
  );
};

export default CreateArticleForm;

// "url":"https://maidonanews.jp/article/14999647",
//   "description":"関西発のニュースサイトで関西の新聞社など各メディアが集まり作成している「まいどなニュース」。多彩なメディアが参画し、各社の編集ノウハウを活かして読み応えのある記事を届けています。犬との可愛くも面白い日常や、飼育法や保護犬などの社会問題、おすすめの商品・サービス紹介など役に立つ情報が満載です。そんな「まいどなニュース」から、今回おすすめする記事はこちら。散歩中に起きた可愛らしい偶然について紹介しています。飼い主さんへのインタビューも載っていますのでぜひ読んでみてください。",
//   "type":"WEB_PAGE",
//   "isPublished":true
